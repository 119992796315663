import React from "react";
import Phone from "../images/call-b.webp";
import Email from "../images/email.webp";
import Location from "../images/location.webp";
import User from "../images/user.webp";
import sobhalogo from "../images/sobha-logo.webp";
import { NavLink } from "react-router-dom";

export const Contactinfo = () => {
  return (
    <>
      <div className="footer">
        <div className="ftrbox">
          <div className="logo">
            <NavLink to="/">
              <img src={sobhalogo} />
            </NavLink>
          </div>
        </div>
        <div className="ftrbox address">
          <ul>
            <li>
              <img src={Location} alt="" />
            </li>
            <li>
              <em>SOBHA LIMITED</em> <br />
              <strong>REDG & CORPORATE OFFICE :</strong> <br />
              SARJAPUR –MARATHAHALLI ROAD (ORR), DEVERABISANHALLI, BELLANDUR
              POST, <br />
              BANGALORE – 560 103, INDIA. <br /> CIN : L4520IKA1995PLCO018475I{" "}
              <br />
              TEL :<a href="tel:+918049320000">  080 4932 0000</a> <br /> FAX :
              080 4932 0444
              <br />
              Website:
              <a href="https://www.sobha.com/" target="_blank">
                www.sobha.com
              </a>
              <hr />
              <strong>MANUFACTURING UNIT</strong>
              <br />
              <strong>MATTRESS DIVISION</strong> <br />
              PLOT NO 9, KIADB INDUSTRIAL AREA, JIGANI LINK ROAD, BOMMASANDRA,{" "}
              <br />
              BANGALORE – 560 105 INDIA. <br />
              TEL : <a href="tel:+918022631700">080 2783 9700</a>
              <br />
              Email:{" "}
              <a href="mailto:sobha.restoplus@sobha.com">
                sobha.restoplus@sobha.com
              </a>{" "}
              <br />
              Website:{" "}
              <a href="https://sobharestoplus.com/" target="_blank">
                {" "}
                www.sobharestoplus.com
              </a>
            </li>
          </ul>
        </div>
        <div className="ftrbox space_between">
          <ul>
            <li>
              <img src={Phone} alt="" />
            </li>
            <li>
              <a href="tel:+918027839700">080 2783 9700</a>
            </li>
            <li>
              <a href="tel:+919880730400">+91 98807 30400</a>
            </li>
          </ul>
          <ul>
            <li>
              <img src={Email} alt="" />
            </li>
            <li>
              <a href="mailto:sobha.restoplus@sobha.com">
                sobha.restoplus@sobha.com
              </a>
            </li>
          </ul>
        </div>

        <div className="ftrbox icon_size space_between">
          <ul>
            <li>
              <img src={User} alt="" />
            </li>
            <li>
              <span>
                <a
                  href="https://www.facebook.com/sobharestoplusmattress"
                  target="_blank"
                >
                  <i className="fa fa-facebook-official"></i>
                </a>
              </span>
              <span>
                <a
                  href="https://www.instagram.com/sobharestoplusmattress/"
                  target="_blank"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/company/7901591/admin/"
                  target="_blank"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </span>
              <span>
                <a
                  href="https://api.whatsapp.com/send?phone=9880730400"
                  target="_blank"
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
              </span>
            </li>
            <li>
              <br />
              <strong>FOR DEALERSHIP/FRANCHISE</strong> <br />
               Mr. Sajeev T.
              <br />
               Mob: <a href="tel:+918747972197">+91 87479 72197</a>
              <br />
              Email: <a href="mailto:sajeev.t@sobha.com">sajeev.t@sobha.com</a> 
              <br />
              <br />
              <strong>FOR HOSPITALITY</strong> <br />
               Mr. Rajesh Tiwari
              <br />
               Mob: <a href="tel:+919205139112">+91 92051 39112</a>
              <br />
               Email:{" "}
              <a href="mailto:rajesh.tiwari@sobha.com">
                rajesh.tiwari@sobha.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
