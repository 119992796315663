import React, {useState, useEffect} from 'react'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Slider from 'react-slick';
import img01 from './images/TEST IMAGE.webp'
import axios from 'axios';
import {useParams} from "react-router-dom";
import { Helmet } from 'react-helmet';

var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

function createMarkup(content) {
    return { __html: decodeHTML(content) };
}

export const Detailpage2 = () => {

    const { slug, slug2 } = useParams();

    const cat = slug;
    const sub_cat = slug2;
    const [pagedata, setPagedata] = useState([]);

    const [enquirefrm, setActive1] = useState("false");

    const handleToggle1 = () => {
        setActive1(!enquirefrm);
    };

    const [data, setData] = useState([]);
    useEffect(() => {

        let mounted = true;

        // Aos.init({ duration: 2000 });

        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/products2.php?c=${cat}&sc=${sub_cat}`)

            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        }

    }, []);

    console.log( "this is product page ",pagedata);
    if (pagedata.length == 0) {
        console.log('no data');
        return null;
    }
    // const colorTag = pagedata.products.color_tag.split(",");

    var settings = {
        dots: false,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed:5000,
        cssEase: "linear",
      };

    var settings1 = {
        dots: false,
        navigation: true,
        loop: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed:5000,
        cssEase: "linear",
        responsive: [
          {
              breakpoint: 980,
              settings: {
                  slidesToShow: 1,
              }
          },
          {
              breakpoint: 680,
              settings: {
                  slidesToShow: 1,
              }
          },
          {
              breakpoint: 480,
              settings: {
                  slidesToShow: 1,
              }
          }
      ]
      };
  return (
    <>
   <Helmet>
  <title>{`${pagedata.subcategory} | Sobha Restoplus`}</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
    <Header />
      <section id='banner' className='pb-0'>
      <div className='banner'>
        <div className='container'>
            <div className='row'>
                
        <Slider {...settings}>
            <div>
              <div className='sliderrow'>
                <div className='slideimg'>
                <img src={pagedata.sub_cat_bimage} alt='Banner' />
                  </div>
                  <div className='slidecont'>
                  <h3 dangerouslySetInnerHTML={createMarkup(pagedata.remarks)}></h3>
                  </div>
                </div>
            </div>
    </Slider>
      </div>
      
      </div>
        </div>
  </section>
      <section id='pillows'>
          <div className='pillows'>
              <div className='container'>
                  <div className='row'>
                      <div className='web-container'>
                        <div className='title'>
                            <span>{pagedata.subcategory}</span>
                        </div>
                        <div className={pagedata.nodescp==1 ? "pillowpt nodescp" : "pillowpt"}>
                            
                            {/* {pagedata.products.map(productsmerged => ( */}
                            {Object.keys(pagedata.products).map((value2) => {
                                return <>
                                {pagedata.products[value2].mID==1010 ?
                                <div className='title'>
                                  <span>{pagedata.subcategory} Accessories</span>
                                </div>
                                : null}
                                <div className='pillowstn'>
                            {Object.keys(pagedata.products[value2].innerpro).map(value => {
                                const productImages = pagedata.products[value2].innerpro[value].product_images.split("~");
                            return <>
                            <div className='pillowbox'>
                          <div className='pillowimg'>
                            <img src={productImages[0]} alt=''/>
                          </div>
                          <div className='pillowcont'>
                            <div className='pillowwidth'>
                            <h3 dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].innerpro[value].title)}></h3>
                            <p dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].innerpro[value].p_desc)}></p>
                            </div>
                            </div>
                          </div>
                          </>
                        })}
                        </div>
                        <div className='pillowtable'>
                        <div class="table-responsive">
                          <div className='heading m-0'>
                          <h3>{pagedata.profeaturestag!=1 ? "Specifications" : "Construction"}</h3>
                          </div>
                          {pagedata.products[value2].mID!=1010 ?
                          <table class="table">
                            <thead>
                              <tr>
<th scope="col"></th>
        {Object.keys(pagedata.products[value2].pronames).map(value => {
        return <th scope="col" dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].pronames[value].title)}></th>
        })}
      </tr>
    </thead>
    <tbody>
      {pagedata.profeaturestag!=1 ?
      <>
      {!pagedata.products[value2].prosizes ? (
        <></>
    ) : (
      <tr>
        <th scope="row">SIZE</th>
        {Object.keys(pagedata.products[value2].prosizes).map(value => {
        return <td dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].prosizes[value].title)}></td>
        })}
      </tr>
    )}
    {pagedata.products[value2].fabric ?
      <tr>
        <th scope="row">FABRIC</th>
        {Object.keys(pagedata.products[value2].profabric).map(value => {
        return <td dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].profabric[value].title)}></td>
        })}
      </tr>
      : null}
      {!pagedata.products[value2].procore_material ? (
        <></>
        ) : (
            <tr>
            <th scope="row">CORE MATERIAL</th>
            {Object.keys(pagedata.products[value2].procore_material).map(value => {
            return <td dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].procore_material[value].title)}></td>
            })}
            </tr>
        )}
      {pagedata.products[value2].profeatures ?
      <tr>
        <th scope="row">FEATURES</th>
        {Object.keys(pagedata.products[value2].profeatures).map(value => {
        return <>
        <td>
          <ul>
          {Object.keys(pagedata.products[value2].profeatures[value]).map(value3 => {
            return <li dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].profeatures[value][value3].ptitle)}></li>
          })}
          </ul>
          </td>
          </>
        })}
      </tr>
      : null}
      </>
      :
      <>
        {Object.keys(pagedata.products[value2].profeatures).map(value => {
        return <>
        <tr><th scope="row" dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].profeatures[value].ptitle)}></th>
        {Object.keys(pagedata.products[value2].profeatures[value].pfeature).map(value3 => {
        return <td dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].profeatures[value].pfeature[value3].descp)}></td>
        })}
        </tr>
        </>
        })}
      </>
      }
    </tbody>
  </table>
  :
  <table class="table">
    <thead>
      <tr>
        {Object.keys(pagedata.products[value2].pronames).map(value => {
        return <th scope="col" dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].pronames[value].title)}></th>
        })}
      </tr>
    </thead>
    <tbody>
      {pagedata.profeaturestag!=1 ?
      <>
      {!pagedata.products[value2].prosizes ? (
        <></>
    ) : (
      <>
      {Object.keys(pagedata.products[value2].prosizes).map((value, index1) => {
          const sizeSplit = pagedata.products[value2].prosizes[0].title.split(',').join('<br>');
          return <>
          {index1==0 ?
          <td dangerouslySetInnerHTML={createMarkup(sizeSplit)}></td>
          : null}
        </>
        })}
      </>
    )}
    <>
    {Object.keys(pagedata.products[value2].profabric).map((value, index2) => {
          const fabricSplit = pagedata.products[value2].profabric[0].title.split(',').join('<br>');
        return <>
        {index2==0 ?
        <td dangerouslySetInnerHTML={createMarkup(fabricSplit)}></td>
        : null}
        </>
        })}
        </>
      {pagedata.products[value2].profeatures ?
      <>
      {Object.keys(pagedata.products[value2].profeatures).map((value, index3) => {
        
        return <>
        {index3==0 ?
        <td>
          <ul>
          {Object.keys(pagedata.products[value2].profeatures[value]).map(value3 => {
            return <li dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].profeatures[value][value3].ptitle)}></li>
          })}
          </ul>
          </td>
          : null}
          </>
        })}
      </>
      : null}
      </>
      :
      <>
        {Object.keys(pagedata.products[value2].profeatures).map(value => {
        return <>
        <tr><th scope="row" dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].profeatures[value].ptitle)}></th>
        {Object.keys(pagedata.products[value2].profeatures[value].pfeature).map(value3 => {
        return <td dangerouslySetInnerHTML={createMarkup(pagedata.products[value2].profeatures[value].pfeature[value3].descp)}></td>
        })}
        </tr>
        </>
        })}
      </>
      }
    </tbody>
  </table>
    }
</div>
</div>
                                </>
})}
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    <Footer />
    </>
  )
}
