import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css"
import { Link } from 'react-router-dom';
import { Svg } from './Svg';
import axios from 'axios';
import Phone from "../images/call.webp";
window.weburl = "https://sobharestoplus.com/";

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

// function createMarkup(content) {
//   return { __html: decodeHTML(content) };
// }

export const Header = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    window.addEventListener('scroll', changeBackground);

    return () => {
      window.removeEventListener('scroll', changeBackground);
    }
  }, []);
  const [menu, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!menu);
  };
  const [header, setNavbar] = useState(false);
  const ddd = window.location.href; console.log(ddd);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  // const handleClick = event => {
  //   event.currentTarget.classList.onClick('hemburgur');
  // };
  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(window.weburl + 'api/categories.php');
      if (mounted) {
        setPagedata(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log('no data');
    // return null;
  }
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const hoverClass = isHovered ? 'hover' : '';
  return (
    <header id='header' className={`${hoverClass}`}>
      <div className={header ? 'header fixed' : 'header'}>
        <div className='headerstrip'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>        
              <ul>
                <li><Link to='/store-locator'><strong>Store Locator</strong></Link></li>
                <li><Link to='/warrenty-registration'>Warranty</Link></li>
                <li><Link to='/career'>Career</Link></li>
                <li><Link to='/contact-us'>Contact Us</Link></li>
                <li>
                  <img className='call_Img_header' src={Phone} alt="" />
                  <a href='tel:+918027839700'>080 2783 9700</a>&nbsp;<span></span>&nbsp;<a href='tel:+919880730400'>+91 98807 30400</a>
                </li>
              </ul>
              </div>

            </div>
          </div>
        </div>
        <div className='headerstn'>
          <div className='container'>
            <div className='row'>
              <div className='logo'>
                <NavLink to='/'  ><Svg /></NavLink>
              </div>
              <div className={!menu ? "menu active" : "menu"} >
                <div className='navbar'>
                  <ul>
                  <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <a>ABOUT US</a>
                      <div className='dropdown'>
                          <div className='container'>
                            <div className='menubox'>
                              <div className='sitemapcol'>
                                <ul>
                                  <li><NavLink to="/sobha-restoplus" >SOBHA RESTOPLUS</NavLink></li>
                                  <li><NavLink to='/about-sobha'>SOBHA GROUP</NavLink></li>
                                </ul>
                              </div>
                            </div>
                        </div>
                      </div></li>
                    <li  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <a href="/category/mattresses">Mattresses</a>
                      <div className='dropdown'>
                          <div className='container'>
                            <div className='menubox'>
                              <div className='sitemapcol'>
                                {pagedata.categories ?
                                  <ul>
                                  {Object.keys(pagedata.categories.mattresses).map(valueH => {
                                    
                                      return <li><a href={pagedata.categories.mattresses[valueH].layout == 1 ? "/products/" + pagedata.categories.mattresses[valueH].categoryslug + "/" + pagedata.categories.mattresses[valueH].slug : "/product/" + pagedata.categories.mattresses[valueH].categoryslug + "/" + pagedata.categories.mattresses[valueH].slug}>{pagedata.categories.mattresses[valueH].sub_category}</a></li>
                                    })}
                                  </ul>
                                  : null} 
                              </div>
                            </div>
                          </div>
                        </div>
                    </li>
                    <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <a > ACCESSORIES</a>
                      <div className='dropdown'>
                          <div className='container'>
                            <div className='menubox'>
                              <div className='sitemapcol'>
                                <ul>
                                <li>
                                  <a href="/category/sleepaccessories">SLEEP ACCESSORIES</a>
                                </li>
                               <li><a href="/category/bathaccessories">BATH ACCESSORIES</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                    </li>

                    <li  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <a href="/category/hospitality">Hospitality</a>
                      <div className='dropdown'>
                          <div className='container'>
                            <div className='menubox'>
                              <div className='sitemapcol'>
                                {pagedata.categories ?
                                  <ul>
                                  {Object.keys(pagedata.categories.hospitality).map(valueH => {
                                    
                                      return <li><a href={pagedata.categories.hospitality[valueH].layout == 1 ? "/products/" + pagedata.categories.hospitality[valueH].categoryslug + "/" + pagedata.categories.hospitality[valueH].slug : "/product/" + pagedata.categories.hospitality[valueH].categoryslug + "/" + pagedata.categories.hospitality[valueH].slug}>{pagedata.categories.hospitality[valueH].sub_category}</a></li>
                                    })}
                                  </ul>
                                  : null} 
                              </div>
                            </div>
                          </div>
                        </div>
                    </li>
                  
                    {/* <li>
                    <a href="/products/hospitality"> HOSPITALITY</a>
                      </li> */}
                    <li>
                    <a href="/trade-enquiry">TRADE ENQUIRY</a>
                      </li>
                <li className='mobile-show'><Link to='/store-locator'><strong>Store Locator</strong></Link></li>
                <li className='mobile-show'><Link to='/warrenty-registration'>Warranty</Link></li>
                <li className='mobile-show'><Link to='/career'>Career</Link></li>
                <li className='mobile-show'><Link to='/contact-us'>Contact Us</Link></li>
              </ul>
                </div>
                <div className='d-none'>
                <button onClick={handleToggle} className={menu ? 'hemburgur' : 'hemburgur active'}><span></span><span></span><span></span></button>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header;