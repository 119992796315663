import React, { useState, useEffect, useRef } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import img01 from "./images/TEST IMAGE.webp";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { Helmet } from 'react-helmet'

var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Warrenty = () => {
  const [show, toggleShow] = useState(false);
  const warrantyYears = Array.from({ length: 15 }, (_, index) => index + 1);
  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setselectedProduct] = useState("");

  useEffect(() => {
    axios
      .get(window.weburl + "api/get_categories.php")
      .then((response) => setCategories(response.data))
      .catch((error) => console.log(error));
  }, []);

  function getProducts(category) {
    axios
      .get(
        window.weburl + `api/get_products.php?cid=${category}`
      )
      .then((response) => setProducts(response.data))
      .catch((error) => console.log(error));
    document.getElementById("user_warranty").value = "";
  }
  function getWarranty(event) {
    var selectedIndex = event.target.selectedIndex;
    var selectedOption = event.target.options[selectedIndex];
    var attributeValue = selectedOption.getAttribute("warrantyperiod");
    document.getElementById("user_warranty").value =
      attributeValue != 0 && attributeValue != null ? attributeValue : "";
  }

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    axios
      .get(window.weburl + "api/get_states.php")
      .then((response) => setStates(response.data))
      .catch((error) => console.log(error));
  }, []);

  function getCities(state) {
    axios
      .get(
        window.weburl + `api/get_cities.php?sid=${state}`
      )
      .then((response) => setCities(response.data))
      .catch((error) => console.log(error));
  }

  // Insert Query Start
  const [user_name, setusername] = useState();
  const [user_mobile, setusermobile] = useState();
  const [user_email, setuseremail] = useState();
  const [user_warranty, setuserwarranty] = useState();
  const [user_date, setuserdate] = useState();
  const [user_source, setusersource] = useState();
  const [dealer_name, setdealername] = useState();
  const [dealer_code, setdealercode] = useState();
  const [invoice_amount, setinvoiceamount] = useState();
  const [user_mrp, setusermrp] = useState();
  const [dealer_pincode, setdealerpincode] = useState();

  // Focus
  const ref_name = useRef(null);
  const ref_mobile = useRef(null);
  const ref_email = useRef(null);
  const ref_category = useRef(null);
  const ref_product = useRef(null);
  const ref_warranty = useRef(null);
  const ref_date = useRef(null);
  const ref_source = useRef(null);
  const ref_dealer_name = useRef(null);
  const ref_dealer_code = useRef(null);
  const ref_invoice_amount = useRef(null);
  const ref_user_mrp = useRef(null);
  const ref_state = useRef(null);
  const ref_city = useRef(null);
  const ref_dealer_pincode = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);

  const handledisable = () => {
    if (user_name && isValidPhone(user_mobile)) {
      const element = document.getElementById("submit_button");
      element.classList.remove("disabled");
      setIsDisabled(false);
    } else {
      const element = document.getElementById("submit_button");
      element.classList.add("disabled");
      setIsDisabled(true);
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidPhone(phone) {
    const regex = /^[6-9]\d{9}$/gi;
    return regex.test(phone);
  }

  const handleSubmit = () => {
    if (!user_name) {
      alert("Please enter name");
      ref_name.current.focus();
      return false;
    } else if (!isValidPhone(user_mobile)) {
      alert("Please enter Mobile number");
      ref_mobile.current.focus();
      return false;
    }
    // else if (!user_warranty) {
    //   alert("Please enter Warranty");
    //   ref_warranty.current.focus();
    //   return false;
    // }
    else {
      document.getElementById("submit_button").innerText='SUBMITTING...';
      const url = window.weburl + "api/insert_hook.php";
      // alert(url);
      let fData = new FormData();
      // alert(selectedCategory);
      fData.append("user_name", user_name);
      fData.append("user_mobile", user_mobile);
      fData.append("user_email", user_email ? user_email : "");
      fData.append("catID", selectedCategory ? selectedCategory : "");
      fData.append("productID", selectedProduct ? selectedProduct : "");
      fData.append("user_warranty", user_warranty ? user_warranty : "");
      fData.append("user_date", user_date ? user_date : "");
      fData.append("user_source", user_source ? user_source : "");
      fData.append("dealer_name", dealer_name ? dealer_name : "");
      fData.append("dealer_code", dealer_code ? dealer_code : "");
      fData.append("invoice_amount", invoice_amount ? invoice_amount : "");
      fData.append("user_mrp", user_mrp ? user_mrp : "");
      fData.append("user_state", selectedState ? selectedState : "");
      fData.append("user_city", selectedCity ? selectedCity : "");
      fData.append("dealer_pincode", dealer_pincode ? dealer_pincode : "");
      fData.append("td_name", "warranty");
      // alert(fData);
      axios
        .post(url, fData)
        .then((response) => alert(response.data))
        .catch((error) => console.log(error));
        console.log(true);
      
      document.getElementById("submit_button").innerText='SUBMIT';
      document.getElementById("user_name").value='';
      document.getElementById("user_mobile").value='';
      document.getElementById("user_email").value='';
      document.getElementById("selectedCategory").value='';
      document.getElementById("selectedProduct").value='';
      document.getElementById("user_warranty").value='';
      document.getElementById("user_date").value='';
      document.getElementById("user_source").value='';
      document.getElementById("dealer_name").value='';
      document.getElementById("dealer_code").value='';
      document.getElementById("invoice_amount").value='';
      document.getElementById("user_mrp").value='';
      document.getElementById("selectedState").value='';
      document.getElementById("selectedCity").value='';
      document.getElementById("dealer_pincode").value='';

    }
  };
  return (
    <>
      <Helmet>
        <title>Warranty | Sobha Restoplus</title>
        <meta name="description" content="" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {show && (
        <div className="form_thankyou">
          <div className="form_thankyou_data">
            <i
              className="crs_btn"
              onClick={() => {
                toggleShow(!show);
              }}
            >
              &#10006;
            </i>
            <div>
              <h5>Thank you for your contacting</h5>
              <span>Our expert will be in touch with you shortly.</span>
            </div>
          </div>
        </div>
      )}
      <section id="whiteheader" className="p-0">
        <Header />
      </section>

      <section id="registration">
        <div className="registration">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <Tabs>
                  <div className="regnav">
                    <TabList>
                      <Tab>WARRANTY REGISTRATION</Tab>
                      <Tab>WARRANTY POLICY</Tab>
                    </TabList>
                  </div>

                  <TabPanel>
                    <div className="registration">
                      <div className="title">
                        <h3>Register Your Purchase</h3>
                        <p>
                          Sobha Restoplus mattresses come with a
                          best-in-industry warranty of up to 10 years. Use the
                          Warranty Registration Form below to register for
                          warranty to be eligible for any claims in the future.
                        </p>
                      </div>
                      {/* <form
                        onSubmit={() => {
                          toggleShow(!show);
                        }}
                      > */}
                        <div className="form bg-white">
                          <div className="formstn">
                            <ul>
                              <li>
                                <input
                                  type="text"
                                  name="user_name"
                                  id="user_name"
                                  placeholder="Name*"
                                  pattern="[a-zA-Z\s]+"
                                  required
                                  value={user_name}
                                  onChange={(e) => {
                                    setusername(e.target.value);
                                  }}
                                  onKeyUp={handledisable}
                                  ref={ref_name}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  name="user_mobile"
                                  id="user_mobile"
                                  placeholder="Phone number*"
                                  value={user_mobile}
                                  onChange={(e) => {
                                    setusermobile(e.target.value);
                                  }}
                                  onKeyUp={handledisable}
                                  ref={ref_mobile}
                                />
                              </li>
                              <li>
                                <input
                                  type="email"
                                  name="user_email"
                                  id="user_email"
                                  placeholder="Email"
                                  value={user_email}
                                  onChange={(e) => {
                                    setuseremail(e.target.value);
                                  }}
                                  ref={ref_email}
                                />
                              </li>
                              <li>
                                <select
                                  name="selectedCategory"
                                  id="selectedCategory"
                                  value={selectedCategory}
                                  onChange={(event) => {
                                    setSelectedCategory(event.target.value);
                                    getProducts(event.target.value);
                                  }}
                                  ref={ref_category}
                                >
                                  <option value="">Select Category</option>
                                  {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                      {category.category}
                                    </option>
                                  ))}
                                </select>
                              </li>
                              <li>
                                <select
                                  name="selectedProduct"
                                  id="selectedProduct"
                                  value={selectedProduct}
                                  onChange={(event) => {
                                    setselectedProduct(event.target.value);
                                    getWarranty(event);
                                  }}
                                  ref={ref_product}
                                >
                                  <option value="">Select Product</option>
                                  {products.map((product) => (
                                    <option
                                      key={product.id}
                                      value={product.id}
                                      dangerouslySetInnerHTML={createMarkup(
                                        product.title
                                      )}
                                      warrantyperiod={product.warranty_period}
                                    ></option>
                                  ))}
                                </select>
                              </li>
                              <li>
                                <select
                                  name="user_warranty"
                                  id="user_warranty"
                                  value={user_warranty}
                                  onChange={(e) => {
                                    setuserwarranty(e.target.value);
                                  }}
                                  ref={ref_warranty}
                                >
                                  <option value="">Warranty Period*</option>
                                  {warrantyYears.map((number) => (
                                    <option value={number}>
                                      {number} Years
                                    </option>
                                  ))}
                                </select>
                              </li>
                              <li>
                                <span>Purchase Date</span>
                                <input
                                  type="date"
                                  name="user_date"
                                  id="user_date"
                                  value={user_date}
                                  onChange={(e) => {
                                    setuserdate(e.target.value);
                                  }}
                                  ref={ref_date}
                                />
                              </li>
                              <li>
                                <select
                                  name="user_source"
                                  id="user_source"
                                  value={user_source}
                                  onChange={(e) => {
                                    setusersource(e.target.value);
                                  }}
                                  ref={ref_source}
                                >
                                  <option value="">Purchase Source</option>
                                  <option value="Online Retailer">
                                    Online Retailer
                                  </option>
                                  <option value="Our Website">
                                    Our Website
                                  </option>
                                  <option value="Authorized Dealer">
                                    Authorized Dealer
                                  </option>
                                  <option value="Other">Other</option>
                                </select>
                              </li>
                              <li>
                                <span>
                                  If purchased from the dealer store, mention
                                  the dealer’s name
                                </span>
                                <input
                                  type="text"
                                  placeholder="Dealer Name"
                                  name="dealer_name"
                                  id="dealer_name"
                                  value={dealer_name}
                                  onChange={(e) => {
                                    setdealername(e.target.value);
                                  }}
                                  ref={ref_dealer_name}
                                />
                              </li>
                              <li>
                                <span>& Dealer Code</span>
                                <input
                                  type="text"
                                  placeholder=""
                                  name="dealer_code"
                                  id="dealer_code"
                                  value={dealer_code}
                                  onChange={(e) => {
                                    setdealercode(e.target.value);
                                  }}
                                  ref={ref_dealer_code}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder="Invoice Amount"
                                  name="invoice_amount"
                                  id="invoice_amount"
                                  value={invoice_amount}
                                  onChange={(e) => {
                                    setinvoiceamount(e.target.value);
                                  }}
                                  ref={ref_invoice_amount}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder="MRP Amount"
                                  name="user_mrp"
                                  id="user_mrp"
                                  value={user_mrp}
                                  onChange={(e) => {
                                    setusermrp(e.target.value);
                                  }}
                                  ref={ref_user_mrp}
                                />
                              </li>
                              <li>
                                <select
                                  name="selectedState"
                                  id="selectedState"
                                  value={selectedState}
                                  onChange={(event) => {
                                    setSelectedState(event.target.value);
                                    getCities(event.target.value);
                                  }}
                                  ref={ref_state}
                                >
                                  <option value="">Select State</option>
                                  {states.map((state) => (
                                    <option
                                      key={state.stateid}
                                      value={state.stateid}
                                    >
                                      {state.sname}
                                    </option>
                                  ))}
                                </select>
                              </li>
                              <li>
                                <select
                                  name="selectedCity"
                                  id="selectedCity"
                                  value={selectedCity}
                                  onChange={(event) =>
                                    setSelectedCity(event.target.value)
                                  }
                                  ref={ref_city}
                                >
                                  <option value="">Select City</option>
                                  {cities.map((city) => (
                                    <option
                                      key={city.cityid}
                                      value={city.cityid}
                                    >
                                      {city.cityname}
                                    </option>
                                  ))}
                                </select>
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder="Pincode"
                                  name="dealer_pincode"
                                  id="dealer_pincode"
                                  value={dealer_pincode}
                                  onChange={(e) => {
                                    setdealerpincode(e.target.value);
                                  }}
                                  ref={ref_dealer_pincode}
                                />
                              </li>
                              <li>
                                <strong>
                                  Upload Invoice Image and MRP Sticker**
                                </strong>
                                <input type="file" />
                              </li>
                              <li>
                                <strong>Disclaimer</strong>
                                <p>
                                  {" "}
                                  Submission of above mentioned details
                                  tentatively confirm your product warranty.
                                  However, hard copy or soft copy (in clear
                                  visible condition) of the product invoice &
                                  product MRP label will be required at the time
                                  of actual warranty claim submission.
                                </p>
                              </li>
                              <li>
                                <strong>Note*</strong>
                                <ul>
                                  <li>
                                    While uploading please choose the Invoice
                                    and MRP sticker together.
                                  </li>
                                  <li>
                                    Supported file formats: png, jpg, jpeg and
                                    pdf
                                  </li>
                                  <li>File(s) size limit is 20MB.</li>
                                </ul>
                              </li>
                              <li>
                                <input type="checkbox" id="check" />
                                <label for="check">
                                  I hereby give Sobha Restoplus consent to
                                  contact me via email, message, WhatsApp or
                                  call for timely assistance.
                                </label>
                              </li>
                            </ul>
                            <div className="btns">
                              <div class="button button-2">
                                <input type="submit" value="submit" id="submit_button" disabled={isDisabled} onClick={handleSubmit} className="disabled"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      {/* </form> */}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="policystn">
                      <div className="policybox">
                        <h3>Claim Warranty</h3>
                        <p>
                          We at SOBHA Restoplus go to great lengths to keep you
                          satisfied right though the useful life of the
                          mattress. To help us in this endeavor in validating
                          your purchase, we request you to forward the purchase
                          details within the first 15 days to Restoplus either
                          by mail or by SMS to the below mentioned co-ordinates.{" "}
                          <a href="mailto:crm.restoplus@sobha.com">
                            E-mail: crm.restoplus@sobha.com
                          </a>{" "}
                          / <a href="sms:+919880730400">SMS: +91 9880730400</a>
                        </p>
                      </div>
                      <div className="policybox">
                        <h3>Terms & Conditions</h3>
                        <p>
                          Mattresses are warrantied against any manufacturing
                          defect for a period varying from 1 to 10 years from
                          the date of purchase. The mattress will be repaired if
                          there are any manufacturing defects as mentioned
                          below.
                        </p>
                      </div>
                      <div className="policybox">
                        <h3>Warranty -What’s covered</h3>
                        <ul>
                          <li>
                            A mattress warranty is your protection against
                            defective workmanship, or product failure. this
                            includes, but is not limited to, defects such as
                            coils or wires that come loose or poke through the
                            fabric, excessive coil, or foam compression,
                            splitting of wood frames.
                          </li>
                          <li>
                            Every mattress has a base/cot that adds to the
                            lifespan of the mattress. When you purchase a
                            mattress, make sure you also purchase a new base for
                            the best support.
                          </li>
                          <li>
                            If a slatted base / cot is used, ensure that a hard
                            board or plywood or particle wood or a similar
                            material is inserted between the mattress and the
                            slatted platform, thus supporting the entire surface
                            of the mattress (We do not recommend to place the
                            mattress on the floor).
                          </li>
                          <li>
                            Use of an open slatted mattress support system (with
                            more than 1” Gap between Slats) or base without the
                            addition of a solid platform may damage your
                            mattress and void your warranty.
                          </li>
                          <li>
                            A Roll-packed mattress will bounce to its original
                            shape and volume within 96hrs, of unrolling and
                            removing the polythene cover.
                          </li>
                        </ul>
                      </div>
                      <div className="policybox">
                        <h3>Warranty -What’s not covered</h3>
                        <p>
                          The following is a general list of what’s NOT covered
                          under our mattress warranty:
                        </p>
                        <ul>
                          <li>
                            The cost of transportation to and from your home.
                          </li>
                          <li>
                            Body impressions that measure …lower than 37mm{" "}
                          </li>
                          <li>Normal wear and tear. </li>
                          <li>
                            Damage related to obvious abuse (walking, jumping,
                            or standing on & folding).
                          </li>
                          <li>
                            Pilling or Balling of Knitted fabrics are not
                            manufacturing defects and hence not covered by this
                            warranty. Pilling / Balling being superficial will
                            not affect/Change the either the Comfort or the
                            Support that the mattress is meant to provide.
                          </li>
                          <li>Stained or soiled mattresses.</li>
                          <li>Bent border wires.</li>
                          <li>Damages to Mattress handles.</li>
                          <li>Accidental damages at the users end.</li>
                          <li>Infestation by insects or rodents.</li>
                          <li>Comfort issues (unless related to a defect).</li>
                          <li>
                            Mattress placed an inadequate foundations or bases
                            or cots
                          </li>
                          <li>
                            Fabric / Foam / Padding material Damage due to hot
                            press or hot iron being used on the mattress.
                          </li>
                          <li>
                            Normal changes in softness or recovery time of
                            high-density foams, including memory foam due to
                            normal temperature or humidity.
                          </li>
                          <li>
                            Fabric issues (unless it is affecting the support or
                            comfort) are generally not covered by this warranty.
                          </li>
                          <li>
                            Sagging due to a lack of proper frame support.
                          </li>
                          <li>
                            Loose threads, Peeling or stretching of fabrics.
                          </li>
                          <li>Burns, cuts or tear</li>
                          <li>
                            Opening of stitches (both quilt and tape edge) are
                            reported after 15 days of the purchase date will not
                            be covered under warranty.
                          </li>
                          <li>
                            A mattress that has already been repaired by self or
                            a third party.
                          </li>
                        </ul>
                      </div>
                      <div className="policybox">
                        <h4>
                          The transportation cost and handling charges towards
                          repair / replacement of mattress:
                        </h4>
                        <ul>
                          <li>Defect arises in the first year – NO charges.</li>
                          <li>
                            Defect arises after first year – one side
                            transportation must be borne by customer; the
                            delivery after repair will be taken care of by Sobha
                            Limited.
                          </li>
                          <li>
                            NOTE: Warranty period does not get extended after
                            repair / replacement. it remains same
                          </li>
                        </ul>
                      </div>
                      <div className="policybox">
                        <h4>
                          For Repair/Replacement Of Mattress Core Material, The
                          Company will Charge as Indicated Below Depending on
                          The Warranty Period.
                        </h4>
                        <p>Below 01 year – NO charges.</p>
                        <ul>
                          <li>
                            1 st – 2 nd year, 8% of MRP prevailing at the time
                            of complaint.
                          </li>
                          <li>
                            2 nd – 3 rd year, 18 % of MRP prevailing at the time
                            of complaint.
                          </li>
                          <li>
                            3 rd – 4 th year, 30% of MRP prevailing at the time
                            of complaint.
                          </li>
                          <li>
                            4 th – 5 th year, 35% of MRP prevailing at the time
                            of complaint.
                          </li>
                          <li>
                            5 th – 10 th year, 45% of MRP prevailing at the time
                            of complaint.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
