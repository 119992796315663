import React, {useState, useEffect} from 'react'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import img01 from './images/TEST IMAGE.webp'
import Slider from 'react-slick';
import pillow01 from './images/pillow01.webp'
import pillow02 from './images/pillow02.webp'
import pillow03 from './images/pillow03.webp'
import pillow04 from './images/pillow04.webp'
import pillow05 from './images/pillow05.webp'
import pillow06 from './images/pillow06.webp'
import axios from 'axios'
import { Helmet } from 'react-helmet';

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Accessoriesdetail = ({ match }) => {

  const cat = match.params.slug;
    const sub_cat = match.params.slug2;
    const pname = match.params.slug3;
    const [pagedata, setPagedata] = useState([]);

    const [enquirefrm, setActive1] = useState("false");

    const handleToggle1 = () => {
        setActive1(!enquirefrm);
    };


    const [data, setData] = useState([]);
    useEffect(() => {

        let mounted = true;

        // Aos.init({ duration: 2000 });

        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/products.php?c=${cat}&sc=${sub_cat}&p=${pname}`)

            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        }

    }, []);

    console.log(pagedata);
    if (pagedata.length == 0) {
        console.log('no data');
        return null;
    }
    const colorTag = pagedata.products.color_tag.split(",");
    const productImages = pagedata.products.product_images.split("~");
    
  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed:5000,
    cssEase: "linear",
  };
  return (
    <>
    <Helmet>
        <title>Pillows-Sleep Accessories | Sobha Restoplus</title>
        <meta name="description" content="" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
        <Header />
        
      <section id='banner' className='pb-0'>
          <div className='banner'>
            <div className='container'>
                <div className='row'>
                    
            <Slider {...settings}>
                <div>
                  <div className='sliderrow'>
                    <div className='slideimg'>
                    <img src={img01} alt='Banner' />
                      </div>
                      <div className='slidecont'>
                      <h3><span><em>A good</em> night’s </span> <span>sleep for both </span><span>of you.</span></h3>
                      </div>
                    </div>
                </div>
        </Slider>
          </div>
          
          </div>
            </div>
      </section>
      <section id='pillows'>
          <div className='pillows'>
              <div className='container'>
                  <div className='row'>
                      <div className='web-container'>
                        <div className='title'>
                            <span>Pillows</span>
                        </div>
                        <div className='pillowpt'>
                        <div className='pillowstn'>
                          <div className='pillowbox'>
                          <div className='pillowimg'>
                            <img src={pillow01} alt='' />
                          </div>
                          <div className='pillowcont'>
                            <div className='pillowwidth'>
                            <h3>ULTRA FIRM LATEX PILLOW</h3>
                            <p>A premium pillow with a latex block core imported from Belgium, and furnished with a 300 thread-count fabric cover. A great choice for side sleepers and heavier individuals, since its greater density enables the spine to maintain a neutral position by maintaining the loft of the pillow, thereby preventing stiffness and pain.</p>
                            </div>
                            </div>
                          </div>
                          <div className='pillowbox'>
                          <div className='pillowimg'>
                            <img src={pillow02} alt='' />
                          </div>
                          <div className='pillowcont'>
                          <div className='pillowwidth'>
                            <h3>ULTRA PLUSH - MEMORY FOAM PILLOW</h3>
                            <p>A premium pillow with a latex block core imported from Belgium, and furnished with a 300 thread-count fabric cover. A great choice for side sleepers and heavier individuals, since its greater density enables the spine to maintain a neutral position by maintaining the loft of the pillow, thereby preventing stiffness and pain.</p>
                            </div>
                            </div>
                          </div>
                          <div className='pillowbox'>
                          <div className='pillowimg'>
                            <img src={pillow03} alt='' />
                          </div>
                          <div className='pillowcont'>
                          <div className='pillowwidth'>
                            <h3>ULTRA PLUS - FIBER PILLOW</h3>
                            <p>Made with siliconized fiber and furnished with a 200 thread-count cotton fabric cover, the Ultra Plus is a classic pillow that provides the ideal balance of comfort and support in all sleeping positions.</p>
                            </div>
                            </div>
                          </div>
                        </div>
                        <div className='pillowtable'>
                        <div class="table-responsive">
                          <div className='heading m-0'>
                          <h3>Specifications</h3>
                          </div>
  <table class="table">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Ultra Firm Latex Pillow </th>
        <th scope="col">ULTRA PLUSH - MEMORY FOAM PILLOW</th>
        <th scope="col">ULTRA PLUS - FIBER PILLOW</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">SIZE</th>
        <td>24” x 16” x 5.5”</td>
        <td>24” x 16” x 5.5”</td>
        <td>27” x 17”</td>
      </tr>
      <tr>
        <th scope="row">FABRIC</th>
        <td>300 TC COTTON</td>
        <td>230 GSM KNITTED COTTON</td>
        <td>200 TC COTTON</td>
      </tr>
      <tr>
        <th scope="row">CORE MATERIAL</th>
        <td>LATEX BLOCK IMPORTED FROM BELGIUM</td>
        <td>45 DENSITY MOULDED MEMORY BLOCK</td>
        <td>15 DENIER VIRGIN CONJUGATED HOLLOW SILICONIZED FIBER</td>
      </tr>
      <tr>
        <th scope="row">FEATURES</th>
        <td>
          <ul>
            <li>SELF-VENTILATING AND MOISTURE REGULATING</li>
            <li>ANTI-FUNGAL AND HYGIENIC</li>
            <li>HIGHLY DURABLE</li>
            <li>EXCELLENT NECK-SUPPORT</li>
          </ul>
          </td>
        <td>
          <ul>
            <li>VISCO-ELASTIC MATERIALS</li>
            <li>ANTI-BACTERIAL PROPERTIES</li>
            <li>100% COTTON</li>
            <li>DURABLE AND LONG-LASTING</li>
            <li>GOOD NECK SUPPORT WITH CONFORMING PROPERTIES</li>
          </ul>
          </td>
          <td>
          <ul>
            <li>100% HOLLOW CONJUGATE SILICONISED FIBER</li>
            <li>RESILIENT AND DURABLE</li>
            <li>NON-ALLERGENIC AND DUST RESISTANT</li>
            <li>ODOURLESS</li>
          </ul>
          </td>
      </tr>
    </tbody>
  </table>
</div>
</div>
<div className='pillowstn'>
                          <div className='pillowbox'>
                          <div className='pillowimg'>
                            <img src={pillow04} alt='' />
                          </div>
                          <div className='pillowcont'>
                            <div className='pillowwidth'>
                            <h3>SPINE REST PILLOW</h3>
                            <p>A back support cushion with arm-rests, made with siliconised fiber and covered with back brushed cotton fabric. It provides ample support to the spine by aligning it in a seated position, thereby enabling you to sit comfortably upright on the bed while watching TV or enjoying a good book.</p>
                            <ul>
                              <li>
                                <strong>Colours</strong>
                              </li>
                              <li><span>Black</span><span>Gray</span></li>
                            </ul>
                            </div>
                            </div>
                          </div>
                          <div className='pillowbox'>
                          <div className='pillowimg'>
                            <img src={pillow05} alt='' />
                          </div>
                          <div className='pillowcont'>
                          <div className='pillowwidth'>
                          <h3>NECK PILLOW</h3>
                            <p>Made with memory foam and furnished with velour fabric, our neck pillow is ergonomically designed to mold gently around the contours of your neck and shoulders.It provides soft-yet-sturdy support simultaneously to the neck, head, shoulders and back.</p>
                            </div>
                            </div>
                          </div>
                          <div className='pillowbox'>
                          <div className='pillowimg'>
                            <img src={pillow06} alt='' />
                          </div>
                          <div className='pillowcont'>
                          <div className='pillowwidth'>                            
                          <h3>THROW PILLOW</h3>
                            <p>A premium combination of aesthetic beauty and functional form, our throw pillows are made with siliconized fiber and designed to spruce up your home with an added touch of class and quality.</p>
                            </div>
                            </div>
                          </div>
                        </div>
                        <div className='pillowtable'>
                        <div class="table-responsive">
                          <div className='heading m-0'>
                          <h3>Specifications</h3>
                          </div>
  <table class="table">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Ultra Firm Latex Pillow </th>
        <th scope="col">ULTRA PLUSH - MEMORY FOAM PILLOW</th>
        <th scope="col">ULTRA PLUS - FIBER PILLOW</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">SIZE</th>
        <td>18” x 35”</td>
        <td>STANDARD</td>
        <td>STANDARD</td>
      </tr>
      <tr>
        <th scope="row">FABRIC</th>
        <td>COTTON BACK BRUSHED/SIZZLED FABRIC</td>
        <td>VELOUR FABRIC WITH ZIP </td>
        <td>UPHOLSTERY FABRIC</td>
      </tr>
      <tr>
        <th scope="row">CORE MATERIAL</th>
        <td>15 DENIER VIRGIN CONJUGATED HOLLOW SILICONIZED FIBER</td>
        <td>MEMORY FOAM BLOCK</td>
        <td>15 DENIER VIRGIN CONJUGATED HOLLOW SILICONISED FIBER</td>
      </tr>
      <tr>
        <th scope="row">FEATURES</th>
        <td>
          <ul>
            <li>100% HOLLOW CONJUGATE SILICONISED FIBER</li>
            <li>RESILIENT AND DURABLE</li>
            <li>NON-ALLERGENIC AND DUST RESISTANT</li>
            <li>ODOURLESS</li>
          </ul>
          </td>
        <td>
          <ul>
            <li>VISCO-ELASTIC MATERIALS</li>
            <li>ANTI-BACTERIAL PROPERTIES</li>
            <li>DURABLE AND LONG-LASTING, RETAINS SHAPE</li>
            <li>IDEAL NECK SUPPORT </li>
          </ul>
          </td>
          <td>
          <ul>
            <li>RESILIENT AND DURABLE</li>
            <li>NON-ALLERGENIC AND DUST RESISTANT</li>
            <li>ODOURLESS</li>
          </ul>
          </td>
      </tr>
    </tbody>
  </table>
</div>
</div>
</div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
        <Footer />
    </>
  )
}
