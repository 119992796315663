import React from 'react'
import Certificate01 from '../images/certificate01.webp'
import Certificate03 from '../images/certificate03.webp'
import Certificate04 from '../images/Cert_logo_5.webp'
import Certificate05 from '../images/certificate05.webp'
import Certificate06 from '../images/certificate06.webp'

export const Certificate = () => {
  return (
    <>
         <section id='certificate'>
      <div className='certificate'>
        <div className='container'>
          <div className='row'>
              <div className='web-container'>
                <div className='certificatestn'>
                  <div className='title'>
                      <span>STANDARDS & CERTIFICATIONS</span>
                  </div>
                  <div className='certificatelogo'>
                    <h4>Certified comfort</h4>
                    <ul>
                      <li><img src={Certificate01}  alt=''/></li>
                      <li><img src={Certificate03}  alt=''/></li>
                      <li><img src={Certificate04}  alt=''/></li>
                    </ul>
                    <h4>Memebership</h4>
                    <ul>
                      <li><img src={Certificate05}  alt=''/></li>
                      <li><img src={Certificate06}  alt=''/></li>
                    </ul>
                  </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      </section>
    </>
  )
}
