import React, {useState, useEffect} from 'react'
import Header from './components/Header'
import { Footer } from './components/Footer'
import { Dropdown } from 'semantic-ui-react'
import Locationicon from './images/locationicon.webp'
import Callicon from './images/callicon.webp'
import {useParams} from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet'

var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };
  
  function createMarkup(content) {
    return { __html: decodeHTML(content) };
  }

export const Store_locator = () => {

    const { slug, slug2 } = useParams();
    const stateID = (slug) ? slug : '';
    const cityID = (slug2) ? slug2 : '';
    // State Dropdown Code
    const [selectedValue, setSelectedValue] = useState(stateID);
    const [states, setStates] = useState([]);
    const stateDropdownChange = (event, data) => {
        const selectedValue = data.value;
        window.location.href="/store-locator/" + selectedValue;
      };
    useEffect(() => {
        axios.get(window.weburl + `api/store_locator.php`)
          .then(response => setStates(response.data.states))
          .catch(error => console.log(error));
      }, []);

    // City Dropdown Code
    const [selectedValue2, setSelectedValue2] = useState(cityID);
    const [cities, setCities] = useState([]);
    const cityDropdownChange = (event, data) => {
        const selectedValue2 = data.value;
        window.location.href="/store-locator/" + selectedValue + "/" + selectedValue2;
      };
    useEffect(() => {
        axios.get(window.weburl + `api/store_locator.php?sid=${stateID}`)
          .then(response => setCities(response.data.cities))
          .catch(error => console.log(error));
      }, []);
      
    //   Location Data Code Start
    const [pagedata, setPagedata] = useState([]);
    
      useEffect(() => {
      let mounted = true;
      const loadData = async () => {
       const response = await axios.get(window.weburl + `api/store_locator.php?sid=${stateID}&cid=${cityID}`)
       if(mounted){
           setPagedata(response.data.location);
       }
    }
    loadData();
    return () => {
        mounted = false;
      };
}, []);
    return (
        <>
            <Helmet>
  <title>Store-Locator | Sobha Restoplus</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
            <Header />
            <section id='banner' className='store_locator'>
                <div className='storelocator'>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <div className='slidecont position-static text-center'>
                                        <h3>Find the store near you.</h3>
                                    </div>
                                </div>
                                <div className='dropdowndilter'>
                                    <div className='dropdownbox'>
                                        <Dropdown placeholder='Select State' fluid search selection options={states} onChange={stateDropdownChange} value={selectedValue}/>
                                    </div>
                                    <div className='dropdownbox'>
                                        <Dropdown placeholder='Select City' fluid search selection options={cities} onChange={cityDropdownChange} value={selectedValue2}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='locations'>
                <div className='container'>
                    <div className='row'>
                        <div className='web-container'>
                            <div id="contentcontainer">
                                <div className="lct-lft">
                                    <div className="lct-box">
                                        {pagedata ?
                                        <>
                                        {pagedata.map(locData => (
                                        <ul>
                                            <li><label className="storename" dangerouslySetInnerHTML={createMarkup(locData.fname)}></label></li>
                                            <li><img src={Locationicon} /><label dangerouslySetInnerHTML={createMarkup(locData.location)}></label></li>
                                            <li><a href={"tel:" + locData.phone}><img src={Callicon} /><label dangerouslySetInnerHTML={createMarkup(locData.phone)}></label></a></li>
                                        </ul>
                                        ))}
                                        </>
                                        : null}
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
