import React from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import img01 from "./images/Sobha Restoplus.webp";
import { Quality } from "./components/Quality";
import { Certificate } from "./components/Certificate";
import Slider from "react-slick";
import Helmet from 'react-helmet';

export const Aboutus = () => {
  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  return (
    <>
      <Helmet>
        <title>About-Us | Sobha Restoplus</title>
        <meta name="description" content="" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />

      <section id="banner" className="pb-0">
        <div className="banner">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img src={img01} alt="Banner" />
                    </div>
                    <div className="slidecont">
                      <h3>
                        <span></span>
                      </h3>{" "}
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section id="aboutus">
        <div className="aboutus">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="bg-white col-12">
                  <div className="minicontainer">
                    <div className="title">
                      <span>Sobha Restoplus</span>
                      <h3>The Reigning Queen Among Mattresses</h3>
                    </div>
                    <div className="restrocont text-center">
                      <p>
                        For decades, SOBHA has been crafting uncompromising
                        living standards for discerning customers. Choosing a
                        SOBHA Restoplus mattress is the best decision you could
                        make, for it is more than just a luxurious possession,
                        it is a way of discovering the very best of yourself
                      </p>
                    </div>
                    <div className="columsdesign">
                      <div className="box">
                        <h4>LUXURY RANGE WITH SUPREME COMFORT</h4>
                      </div>
                      <div className="box">
                        <h4>EXCELLENT BODY SUPPORT AND SLEEPING POSTURE</h4>
                      </div>
                      <div className="box">
                        <h4>CRAFTED IN A STATE-OF-THE-ART FACILITY</h4>
                      </div>
                      <div className="box">
                        <h4>EUROPEAN MACHINERY AND MATERIALS </h4>
                      </div>
                      <div className="box">
                        <h4>WIDE RANGE FROM 4” TO 14” IN THICKNESS </h4>
                      </div>
                      <div className="box">
                        <h4>STRINGENT QUALITY CHECKS AT EVERY STAGE</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Quality />
      <Certificate />
      <Footer />
    </>
  );
};
