import React, { useState, useEffect } from 'react'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Slider from 'react-slick';
import Icon01 from './images/icon01.webp'
import Icon02 from './images/icon02.webp'
import Icon03 from './images/icon03.webp'
import noimg from './images/no_cat_image.webp'
import { Catelogue } from './components/Catelogue';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { Quality } from './components/Quality';
import Sobhavideo from './images/trialvedio.mp4'
import Sobhamobile from './images/trialvedio.mp4'
import Img01 from './images/slider01.webp'
import Img02 from './images/slider02.webp'  
import Img03 from './images/slider03.webp'
import Img04 from './images/slider04.webp'
import { Helmet } from 'react-helmet';

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Home = () => {
  
  document.addEventListener('contextmenu', event => event.preventDefault());
  function disableShortcuts(e) {
    if (e.keyCode === 123 && e.ctrlKey && e.keyCode === 'I'.charCodeAt(0) &&
        e.ctrlKey && e.keyCode === 'J'.charCodeAt(0) && e.ctrlKey && e.keyCode === 'C'.charCodeAt(0) &&
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))) {
      return false;
    }
  } 
  document.addEventListener('keydown', disableShortcuts);
  
  document.addEventListener('keydown', event => {
    if (event.ctrlKey && event.shiftKey && event.key === 'I') {
        event.preventDefault();
    }
});
document.addEventListener('keydown', function(e) {
  if (e.keyCode === 123) {
    return false;
  }
});
  const cat = "mattresses";

  const [pagedata, setPagedata] = useState([]);


  const [data, setData] = useState([]);
  useEffect(() => {

    let mounted = true;

    // Aos.init({ duration: 2000 });

    const loadData = async () => {
      const response = await axios.get(window.weburl + `api/categories.php?c=${cat}`)

      if (mounted) {
        setPagedata(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    }

  }, []);

  // vedioPlay js
  document.addEventListener('DOMContentLoaded', function() {
    // Get the video element
    const video = document.getElementById('auto_ply');

    // Unmute the video (if needed) and play it
    video.muted = false; // Set to true if you want to keep it muted
    video.play();
  });



  console.log(pagedata);
  if (pagedata.length == 0) {
    console.log('no data');
    return null;
  }

  var settings = {
    dots: false,
    navigation: false,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    arrows: false,
     cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',

  };
  var settings2 = {
    dots: false,
    navigation: true,
    loop: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>
   <Helmet>
  <title>Sobha Restoplus</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
    </Helmet>
    <section id='head' className='p-0'>
      <Header />
      </section>
      <section id='banner' className='bg-white p-0'>
        <div className='banner home-page'>
          <video autoPlay muted loop playsInline  className='desktop-show auto_ply'>
            <source src={Sobhavideo} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>
          {/* <video autoPlay muted loop playsInline className='mobile-show'>
            <source src={Sobhamobile} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video> */}
          <div className='mobileslider mobile-show'>
           <Slider {...settings}>
              <div>
                <div className='sliderimg'>
                    <img src={Img01} alt='Sobha Restoplus' />
                </div>
              </div>
              <div>
                <div className='sliderimg'>
                    <img src={Img02} alt='Sobha Restoplus'/>
                </div>
              </div>
              <div>
                <div className='sliderimg'>
                    <img src={Img03} alt='Sobha Restoplus'/>
                </div>
              </div>
              <div>
                <div className='sliderimg'>
                    <img src={Img04} alt='Sobha Restoplus'/>
                </div>
              </div>
           </Slider>
        </div>
        </div>
      </section>
      <section id='highlights' className='bg-white dotbgimg'>
        <div className='highlightstn'>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='highlight'>
                  <ul>
                    <li><span><img src={Icon01} alt='' /></span><p>WIDE RANGE</p></li>
                    <li><span><img src={Icon02} alt='' /></span><p>WARRANTY</p></li>
                    <li><span><img src={Icon03} alt='' /></span><p>FREE DELIVERY</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='mattresses' className='dotbgimg'>
        <div className='mattresses'>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='title'>
                  <span>Our Mattresses</span>
                  <h3>Choose Your Comfort.</h3>
                </div>
                <div className='comfortslider'>
                  <Slider {...settings2}>
                    {pagedata.subcategories.map(subCats => (
                      <div>
                        <div className='comfortbox adjt_wdth'>
                          <NavLink to={subCats.layout == 1 ? '/products/' + cat + '/' + subCats.slug : '/product/' + cat + '/' + subCats.slug}>
                            <img src={subCats.subcategory_image ? subCats.subcategory_image : noimg} alt='' />
                            <div className='comfortinfo'>
                              <h3 dangerouslySetInnerHTML={createMarkup(subCats.sub_category_html)}></h3>
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Quality />
      <Catelogue cat="" />
      <Footer />
    </>
  )
}
export default Home;
