import React, {useState, useEffect} from 'react'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import img01 from './images/product-banner.webp'
import noimg from './images/noimage.jpg'
import Product02 from './images/bed-02.png'
import Product03 from './images/bed-03.png'
import Product04 from './images/bed-04.png'
import Product05 from './images/bed-05.png'
import Product06 from './images/bed-06.png'
import Product07 from './images/bed-07.png'
import Product08 from './images/bed-08.png'
import Product09 from './images/bed-09.png'
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Catelogue } from './components/Catelogue';
import axios from 'axios';
import {useParams} from "react-router-dom";
import { Helmet } from 'react-helmet';

var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

function createMarkup(content) {
    return { __html: decodeHTML(content) };
}

export const Products = ({ match }) => {

    const { slug, slug2 } = useParams();
    const cat = slug;
    const sub_cat = (slug2 ? slug2 : '');

    const [pagedata, setPagedata] = useState([]);

    const [enquirefrm, setActive1] = useState("false");

    const handleToggle1 = () => {
        setActive1(!enquirefrm);
    };

    const [data, setData] = useState([]);
    useEffect(() => {

        let mounted = true;

        // Aos.init({ duration: 2000 });

        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/products.php?c=${cat}&sc=${sub_cat}`)
            if (mounted) {
                setPagedata(response.data);
                // setData(response.data.products.accordions);
            }
            // console.log("this is response",response)
        }

        loadData();

        return () => {
            mounted = false;
        }

    }, []);

    // console.log("this page data",pagedata);
    if (pagedata.catgegory == 0) {
        console.log('no data');
        // return null;
    }

    var settings = {
        dots: false,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed:5000,
        cssEase: "linear",
      };
  return (
    <>
    <Helmet>
  <title>{`${pagedata.subcategory}  | Sobha Restoplus`}</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
    <Header />
    <section id='banner' className='pb-0'>
          <div className='banner'>
            <div className='container'>
                <div className='row'>
            <Slider {...settings}>
                <div>
                  <div className='sliderrow'>
                    <div className='slideimg'>
                        {pagedata.sub_cat_bimage ?
                        <img src={pagedata.sub_cat_bimage} alt='Banner' />
                        : null}
                      </div>
                      <div className='slidecont'>
                          <h3 dangerouslySetInnerHTML={createMarkup(pagedata.sub_cat_btitle)}></h3>
                      </div>
                    </div>
                </div>
        </Slider>
          </div>
          </div>
            </div>
      </section>
      <section id='products'>
        <div className='products'>
                <div className='container'>
                        <div className='row'>
                                <div className='web-container'>
                                    <div className='title'>
                                            <span>OUR {pagedata.catgegory}</span>
                                            <h3>{pagedata.subcategory}</h3>
                                    </div>
                                    {pagedata.products ?
                                    <div className='productstn'>
                                        {pagedata.products.map(proData => (
                                            <div className='productbox'>
                                                <Link to={"/product/"+cat+"/"+(sub_cat ? sub_cat : 'p')+"/"+proData.slug}>
                                                <div className='productimg'>
                                                    <img src={proData.image? proData.image : noimg} alt='' />
                                                </div>
                                                <div className='productcont'>
                                                    <p dangerouslySetInnerHTML={createMarkup(proData.title)}></p>
                                                    <span>+</span>
                                                </div>
                                                </Link>
                                            </div>
                                            ))}
                                    </div>
                                    : null}
                                </div>
                        </div>
                </div>
        </div>
      </section>
      <Catelogue cat={cat} />
      <Footer />
      </>
  )
}
