import React, { useState, useRef } from "react";
import Header from "./components/Header";
import Phone from "./images/call-b.webp";
import Email from "./images/email.webp";
import Location from "./images/location.webp";
import User from "./images/user.webp";
import img01 from "./images/contact-us.webp";
import { NavLink } from "react-router-dom";
import sobhalogo from "./images/sobha-logo.webp";
import Slider from "react-slick";
import axios from "axios";
import { Contactinfo } from "./components/Contactinfo";
import { Footer } from "./components/Footer";
import { Helmet } from "react-helmet";
var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Contactus = () => {
  const [show, toggleShow] = useState(false);

  // Insert Query Start
  const [user_name, setusername] = useState();
  const [user_mobile, setusermobile] = useState();
  const [user_email, setuseremail] = useState();
  const [user_comment, setusercomment] = useState();

  // Focus
  const ref_name = useRef(null);
  const ref_mobile = useRef(null);
  const ref_email = useRef(null);
  const ref_comment = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);

  const handledisable = () => {
    if (user_name && isValidPhone(user_mobile) && user_mobile) {
      const element = document.getElementById("submit_button");
      element.classList.remove("disabled");
      setIsDisabled(false);
    } else {
      const element = document.getElementById("submit_button");
      element.classList.add("disabled");
      setIsDisabled(true);
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidPhone(phone) {
    const regex = /^[6-9]\d{9}$/gi;
    return regex.test(phone);
  }

  const handleSubmit = () => {
    if (!user_name) {
      alert("Please enter name");
      ref_name.current.focus();
      return false;
    } else if (!isValidPhone(user_mobile)) {
      alert("Please enter Mobile number");
      ref_mobile.current.focus();
      return false;
    } else {
      document.getElementById("submit_button").innerText='SUBMITTING...';
      const url = window.weburl + "api/insert_hook.php";
      let fData = new FormData();
      fData.append("user_name", user_name);
      fData.append("user_mobile", user_mobile);
      fData.append("user_comment", user_comment ? user_comment : "");
      fData.append("user_email", user_email ? user_email : "");
      fData.append("td_name", "contact");
      fData.append("utm_url", window.location.href);
      axios
        .post(url, fData)
        .then((response) => alert(response.data))
        .catch(error => alert(error));
        document.getElementById("submit_button").innerText='SUBMIT';
        document.getElementById("user_name").value='';
        document.getElementById("user_mobile").value='';
        document.getElementById("user_comment").value='';
        document.getElementById("user_email").value='';
      return false;
    }
  };

  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <>
        <Helmet>
  <title>Contact-us | Sobha Restoplus</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
      {" "}
      {show && (
        <div className="form_thankyou">
          <div className="form_thankyou_data" id="form_thankyou_data">
            <i
              className="crs_btn"
              onClick={() => {
                toggleShow(!show);
              }}
            >
              &#10006;
            </i>
            <div>
              <h5 id="messagehead">loading message...</h5>
              <span id="messagedesc"></span>
            </div>
          </div>
        </div>
      )}
      <Header />
      <section id="banner" className="pb-0">
        <div className="banner">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img src={img01} alt="Banner" />
                    </div>
                    <div className="slidecont">
                      <h3>
                        <span></span>
                      </h3>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section id="getintouch">
        <div className="getintouch">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title">
                  <h3>Get In Touch</h3>
                </div>
                <div className="contact">
                  <ul class="trading_parten_form">
                    <li>
                      <input
                        type="text"
                        name="user_name"
                        id="user_name"
                        placeholder="Enter your name*"
                        pattern="[a-zA-Z\s]+"
                        required
                        value={user_name}
                        onChange={(e) => {
                          setusername(e.target.value);
                        }}
                        onKeyUp={handledisable}
                        ref={ref_name}
                      />
                    </li>
                    <li>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        name="user_email"
                        id="user_email"
                        value={user_email}
                        onChange={(e) => {
                          setuseremail(e.target.value);
                        }}
                        ref={ref_email}
                      />
                    </li>
                    <li>
                      <input
                        type="Tel"
                        name="user_mobile"
                        id="user_mobile"
                        placeholder="Enter your phone*"
                        value={user_mobile}
                        onChange={(e) => {
                          setusermobile(e.target.value);
                        }}
                        onKeyUp={handledisable}
                        ref={ref_mobile}
                      />
                    </li>
                    <li>
                      <textarea
                        id="user_comment"
                        name="user_comment"
                        value={user_comment}
                        onChange={(e) => {
                          setusercomment(e.target.value);
                        }}
                        ref={ref_comment}
                        placeholder="Write your comments"
                      ></textarea>
                    </li>
                  </ul>
                  <div className="btns">
                    <div class="button button-2">
                      <input
                        type="submit"
                        value="submit"
                        id="submit_button"
                        onClick={handleSubmit}
                        disabled={isDisabled}
                        className="disabled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactpage p-0">
      <Footer />
      </section>
    </>
  );
};
