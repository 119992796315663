import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

export const Sitemap = () => {
    
  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(window.weburl + 'api/categories.php');
      if (mounted) {
        setPagedata(response.data);
      }
    }

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log('no data');
    // return null;
  }
  return (
    <>
     <div className="footer">
                    <div className="ftrbox">
                    </div>
                    <div className="ftrbox">
                        <h5><Link href="/category/mattresses">Mattresses</Link> </h5>
                        {pagedata.categories ?
                            <ul>
                            {Object.keys(pagedata.categories.mattresses).map(valueH => {
                                   
                                   return <li><a href={pagedata.categories.mattresses[valueH].layout == 1 ? "/products/" + pagedata.categories.mattresses[valueH].categoryslug + "/" + pagedata.categories.mattresses[valueH].slug : "/product/" + pagedata.categories.mattresses[valueH].categoryslug + "/" + pagedata.categories.mattresses[valueH].slug}>{pagedata.categories.mattresses[valueH].sub_category}</a></li>
                                 })}
                            </ul>
                            : null}
                    </div>
                    <div className="ftrbox">
                        <h5><a href="/category/sleepaccessories">SLEEP ACCESSORIES</a></h5>
                        {pagedata.categories ?
                            <ul>
                              {Object.keys(pagedata.categories.sleepaccessories).map(value => {
                                return <li><a href={pagedata.categories.sleepaccessories[value].layout == 1 ? "/products/" + pagedata.categories.sleepaccessories[value].categoryslug + "/" + pagedata.categories.sleepaccessories[value].slug : "/product/" + pagedata.categories.sleepaccessories[value].categoryslug + "/" + pagedata.categories.sleepaccessories[value].slug}>{pagedata.categories.sleepaccessories[value].sub_category}</a></li>
                              })}
                            </ul>
                            : null}
                    </div>
                     <div className="ftrbox">
                        <h5><a href="/category/bathaccessories">BATH ACCESSORIES</a></h5>
                        {pagedata.categories ?
                            <ul>
                              {Object.keys(pagedata.categories.bathaccessories).map(value => {
                                return <li><a href={pagedata.categories.bathaccessories[value].layout == 1 ? "/products/" + pagedata.categories.bathaccessories[value].categoryslug + "/" + pagedata.categories.bathaccessories[value].slug : "/product/" + pagedata.categories.bathaccessories[value].categoryslug + "/" + pagedata.categories.bathaccessories[value].slug}>{pagedata.categories.bathaccessories[value].sub_category}</a></li>
                              })}
                            </ul>
                            : null}
                    </div>  
                     <div className="ftrbox">
                        <h5>ABOUT US</h5>
                        <ul>
                        <li><a href="/sobha-restoplus">SOBHA Restoplus </a></li>
                        <li><a href='/about-sobha'>SOBHA Group</a></li>
                        </ul>   
                    </div>  
                    <div className="ftrbox">
                        <h5>QUICK LINKS</h5>
                        <ul>
                            <li> <a href="/store-locator"> Store Locator</a></li>
                            <li>  <a href="/warrenty-registration">Warranty</a></li>
                            <li>  <a href='/career'>Career</a></li>
                            <li>  <a href="/contact-us">Contact us</a></li>
                        </ul>
                    </div>  
                </div>
    </>
  )
}
