import React, { useState, useRef } from "react";
import catelogue from "../images/Brochure-home.webp";
import Scroll from "../images/downarrow.webp";
// import Brochure from '../images/sobha-restoplus.pdf'
import Brochure from "../images/sobha-restroplus.pdf";
import axios from "axios";
import { useEffect } from "react";
var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Catelogue = ({ catalogue_content , cat }) => {


  const [pageData, setPageData] = useState([]);
  // console.log("this is cat",  cat)
  useEffect(() => {

    let mounted = true;

    // Aos.init({ duration: 2000 });

    const loadData = async () => {
      const response = await axios.get(window.weburl + `api/get_catalogue.php?c=${cat}`)

      if (mounted) {
        setPageData(response.data.catalogue);
      }
      // console.log("this is cat response",response)
    }

    loadData();

    return () => {
      mounted = false;
    }

  }, []);

  // console.log("this is page data",pageData)
  const Brochure=pageData.catalogue_file




  const [show, showToggle] = useState(false);

  // Insert Query Start
  const[user_name, setusername] = useState();
  const[user_mobile, setusermobile] = useState();
  const[user_address, setuseraddress] = useState();
  const[user_email, setuseremail] = useState();

  // Focus
  const ref_category = useRef(null);
  const ref_plan = useRef(null);
  const ref_name = useRef(null);
  const ref_mobile = useRef(null);
  const ref_state = useRef(null);
  const ref_city = useRef(null);
  const ref_address = useRef(null);
  const ref_email = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);

  const handledisable = () => {
  if ((user_name) && isValidPhone(user_mobile) && (user_mobile)) {
    const element = document.getElementById('submit_button');
    element.classList.remove('disabled');
    setIsDisabled(false);
  } else {
    const element = document.getElementById('submit_button');
    element.classList.add('disabled');
    setIsDisabled(true);
  }
};

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function isValidPhone(phone) {
  const regex = /^[6-9]\d{9}$/gi;
  return regex.test(phone);
}

const handleSubmit = ()  => {
  if (!user_name) {
    alert("Please enter name");
    ref_name.current.focus();
    return false;
  }
  else if (!isValidPhone(user_mobile)) {
    alert("Please enter Mobile number");
    ref_mobile.current.focus();
    return false;
  }
  else {
    document.getElementById("submit_button").innerText='SUBMITTING...';
    const url = window.weburl + "api/insert_hook.php";
    let fData = new FormData();
    fData.append('user_name',user_name);
    fData.append('user_mobile',user_mobile);
    fData.append('user_address',(user_address ? user_address : ''));
    fData.append('user_email',(user_email ? user_email : ''));
    fData.append('td_name','catalogue');
    fData.append('utm_url',window.location.href);
    axios.post(url, fData)
    .then(response => {
      if (response.data === 'success') {
        window.open(Brochure, "_blank");
        showToggle(false)
        document.getElementById("submit_button").innerText='SUBMIT';
        document.getElementById("user_name").value='';
        document.getElementById("user_mobile").value='';
        document.getElementById("user_address").value='';
        document.getElementById("user_email").value='';
        alert('Thank you for your contacting\nOur expert will be in touch with you shortly.');
      }
    })
    .catch(error => {
      alert(error);
    });
    // .then(response => ((response.data=='success')  ? window.open(Brochure,"_blank") : ''))
    // .catch(error => alert(error));
    // console.log(true);
    // window.location.reload();
  }
}
// console.log("this is brochoure",pageData.catalogue_file);

  return (
    <>
    {show ? (
      <div className="cat_form center">
        <div className="cat_from_cnt">
        <div className="form">
          <div className="formstn trading_partner">
            <ul>
              <li>
                <input type="text" name="user_name" id="user_name"  placeholder='Your Name*' pattern="[a-zA-Z\s]+"  value={user_name} onChange={(e) => {setusername(e.target.value);}} onKeyUp={handledisable} ref={ref_name}/>
              </li>
              <li>
                <input type="tel" name="user_mobile" id="user_mobile"  placeholder='Mobile Number*' pattern="[0-9]{10}" value={user_mobile} onChange={(e) => {setusermobile(e.target.value);}}  onKeyUp={handledisable} ref={ref_mobile}/>
              </li>
              <li>
              <input type="email" placeholder="Email Address"  name="user_email" id="user_email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address" value={user_email} onChange={(e) => {setuseremail(e.target.value);}} ref={ref_email}/>
              </li>
              <li>
              <input type="text" placeholder='Place'  id="user_address" name="user_address" value={user_address} onChange={(e) => {setuseraddress(e.target.value);}} ref={ref_address}/>
              </li>
            </ul>
            <div className="back_toggle" onClick={() => {
              showToggle(!show);
            }}>&#10006;</div>
            <div className="btns">
              <div class="button button-2">
                <input type="submit" value="submit" id="submit_button" disabled={isDisabled} onClick={handleSubmit} className='disabled'/>
              </div>
            </div>
          </div>
        </div>
        
        </div>
      </div>
    ) : null}
    <section id="catelogue">
        <div className="catelogue">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title">
                  <h3>{pageData.catalogue_title}</h3>
                </div>
                <div className="cateloguestn bg-white" onClick={() => { showToggle(!show); }} >
                  <div className="catelogueleft">
                    <img src={pageData.catalogue_img} alt="" />
                  </div>
                  <div className="catelogueright ">

                    <div className="downloadbtn">
                      <p>{pageData.catalogue_desc}</p>
                      <img src={Scroll} alt="Scroll" onClick={() => { showToggle(!show); }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
