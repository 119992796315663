import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import '../src/font/stylesheet.css'
import '../src/css/style.css';
import '../src/css/header.css';
import '../src/css/footer.css';
import '../src/css/responsive.css';
import Home from './Home';
import { Aboutus } from './Aboutus';
import { Categorypage } from "./Categorypage";
import { Products } from "./Products";
import { Detailpage } from "./Detailpage";
import { Detailpage2 } from "./Detailpage2";
import { Accessoriesdetail } from "./Accessoriesdetail";
import { Warrenty } from "./Warrenty";
import  Trading_form  from "./Trading_form";
import { Contactus } from "./Contactus";
import { Sobha } from './Sobha';
import { Career } from './Career';
import ScrollToTop from './ScrollToTop'
import { Store_locator } from './Store_locator';

function App() {
  return (
    <>
    <Router>
      <ScrollToTop />
    <Routes>      
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sobha-restoplus" element={<Aboutus />} />
        <Route exact path="/about-sobha" element={ <Sobha />}></Route> 
        <Route exact path="/career" element={ <Career />}></Route>     
        <Route exact path="/store-locator" element={ <Store_locator />}></Route>    
        <Route exact path="/store-locator/:slug" element={ <Store_locator />}></Route>    
        <Route exact path="/store-locator/:slug/:slug2" element={ <Store_locator />}></Route>    
        <Route exact path="/category/:slug" element={<Categorypage />}></Route>
        {/* <Route exact path="/products/:slug" element={<Products />}></Route> */}
        <Route exact path="/products/:slug/:slug2" element={<Products />}></Route>
        <Route exact path="/product/:slug/:slug2/:slug3" element={<Detailpage />}></Route>
        <Route exact path="/product/:slug/:slug2" element={ <Detailpage2 />}></Route>
        <Route exact path="/sleep-accessories/pillows" element={ <Accessoriesdetail /> }></Route>
        <Route exact path="/warrenty-registration" element={ <Warrenty />}></Route>
        <Route exact path="/trade-enquiry" element={ <Trading_form  />}></Route>
        <Route exact path="/contact-us" element={ <Contactus />}></Route>   
    </Routes>
    </Router>
    </>    
  );
}

export default App;
