import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer";
import img01 from "./images/Career.webp";
import Slider from "react-slick";
import { Accordion, AccordionItem } from "react-light-accordion";
import axios from "axios";
import { Helmet } from "react-helmet";

var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Career = () => {
  const [pagedata, setPagedata] = useState([]);

  useEffect(() => {
    let mounted = true;
    // Aos.init({ duration: 2000 });
    const loadData = async () => {
      const response = await axios.get(
        window.weburl + `api/jobs.php`
      );

      if (mounted) {
        setPagedata(response.data);
      }
    };
    loadData();
    return () => {
      mounted = false;
    };
  }, []);

  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <>
      <Helmet>
        <title>Career | Sobha Restoplus</title>
        <meta name="description" content="" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />

      <section id="banner" className="pb-0">
        <div className="banner">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div>
                  <div className="sliderrow">
                    <div className="slideimg">
                      <img src={img01} alt="Banner" />
                    </div>
                    <div className="slidecont">
                      <h3>
                        <span></span>
                      </h3>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section id="aboutus">
        <div className="aboutus">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="bg-white col-12">
                  <div className="minicontainer">
                    <div className="title">
                      <span>Career</span>
                      <h3>
                        The Job You've Been
                        <br /> Dreaming Of
                      </h3>
                    </div>
                    <div className="restrocont text-center">
                      <p>
                        We're looking for smart, driven individuals who are
                        ready to dream up new ideas with our team. We provide
                        best-in-class benefits to support and protect our
                        employees beyond the workplace — and help them plan for
                        tomorrow.
                      </p>
                      <p>
                        Forward your resume to
                        <a href="mailto:sobha.restoplus@sobha.com">
                          sobha.restoplus@sobha.com
                        </a>
                        or (WhatsApp) +91 98807 30494
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {pagedata.jobs ? (
        <section id="currentopening">
          <div className="currentopening">
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>
                      Current Job Openings at
                      <br /> SOBHA Restoplus
                    </h3>
                  </div>
                  <Accordion atomic={true}>
                    {pagedata.jobs.map((jobsData) => (
                      <AccordionItem title={jobsData.title}>
                        <div class="jobs_cnt" dangerouslySetInnerHTML={createMarkup(jobsData.descp)}></div>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <Footer />
    </>
  );
};
