import React from 'react'
import client_img1 from "../images/01.webp"
import client_img2 from "../images/02.webp"
import client_img3 from "../images/03.webp"
import client_img4 from "../images/04.webp"
import client_img5 from "../images/05.webp"
import client_img6 from "../images/06.webp"
import client_img7 from "../images/07.webp"
import client_img8 from "../images/08.webp"
import client_img9 from "../images/09.webp"
import client_img10 from "../images/10.webp"
import client_img11 from "../images/11.webp"
import client_img12 from "../images/12.webp"
import client_img13 from "../images/13.webp"
import client_img14 from "../images/14.webp"
import client_img15 from "../images/15.webp"
import client_img16 from "../images/16.webp"
import client_img17 from "../images/17.webp"
import client_img18 from "../images/18.webp"
import client_img19 from "../images/19.webp"
import {useParams} from "react-router-dom";

export const Clientie = ({clietsData}) => {
    // alert(clietsData);
  return (
    <>
    <section id="clientie">
        <div className="clientie dotbgimg">
            <div className="conatiner">
                <div className="row">
                    <div className="web-container">
                        <div className="title">
                            <span>CLIENTELE</span>
                        </div>
                        <div className="client_cnt">
                            <div className="client_box">
                                <ul>
                                    <li>
                                        <img src={client_img1} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img2} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img3} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img4} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img5} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img6} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img7} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img8} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img9} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img10} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img11} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img12} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img13} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img14} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img15} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img16} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img17} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img18} alt="" />
                                      
                                    </li>
                                    <li>
                                        <img src={client_img19} alt="" />
                                      
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
