import React, { useState, useRef } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import img01 from "./images/TEST IMAGE.webp";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { Helmet } from "react-helmet";

var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

const TradingForm = () => {
  const [show, toggleShow] = useState(false);

  // Insert Query Start
  const[user_name, setusername] = useState();
  const[user_mobile, setusermobile] = useState();
  const[user_email, setuseremail] = useState();
  const[user_comment, setusercomment] = useState();
  const[company_name, setcompanyname] = useState();
  const[user_location, setuserlocation] = useState();

  // Focus
  const ref_name = useRef(null);
  const ref_mobile = useRef(null);
  const ref_email = useRef(null);
  const ref_comment = useRef(null);
  const ref_companyname = useRef(null);
  const ref_location = useRef(null);

  const [isDisabled, setIsDisabled] = useState(true);

  const handledisable = () => {
  if ((user_name) && isValidPhone(user_mobile) && (user_mobile)) {
    const element = document.getElementById('submit_button');
    element.classList.remove('disabled');
    setIsDisabled(false);
  } else {
    const element = document.getElementById('submit_button');
    element.classList.add('disabled');
    setIsDisabled(true);
  }
};

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function isValidPhone(phone) {
  const regex = /^[6-9]\d{9}$/gi;
  return regex.test(phone);
}

const handleSubmit = ()  => {
  var messageHead = document.getElementById("messagehead");
  if (!user_name) {
    alert("Please enter name");
    ref_name.current.focus();
    return false;
  }
  else if (!isValidPhone(user_mobile)) {
    alert("Please enter Mobile number");
    ref_mobile.current.focus();
    return false;
  }
  else {
    document.getElementById("submit_button").innerText='SUBMITTING...';
    const url = window.weburl + "api/insert_hook.php";
    let fData = new FormData();
    // alert(user_comment);
    fData.append('user_name',user_name);
    fData.append('user_mobile',user_mobile);
    fData.append('user_email',(user_email ? user_email : ''));
    fData.append('user_message',(user_comment ? user_comment : ''));
    fData.append('company_name',(company_name ? company_name : ''));
    fData.append('user_address',(user_location ? user_location : ''));
    fData.append('td_name','partnerus');
    fData.append('utm_url',window.location.href);
    axios.post(url, fData)
    .then((response) => alert(response.data))
    .catch(error => alert(error));
        document.getElementById("submit_button").innerText='SUBMIT';
        document.getElementById("user_name").value='';
        document.getElementById("user_mobile").value='';
        document.getElementById("user_email").value='';
        document.getElementById("user_comment").value='';
        document.getElementById("company_name").value='';
        document.getElementById("user_location").value='';
    return false;
  }
}
  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  return (
    <>
     <Helmet>
  <title>Trade-Enquire | Sobha Restoplus</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
      {show && (
        <div className="form_thankyou">
          <div className="form_thankyou_data">
            <i className="crs_btn" onClick={() => { toggleShow(!show); }}>
              &#10006;
            </i>
            <div>
              <h5 id="messagehead">Thank you for your enquiry</h5>
              <span>Our expert will be in touch you Shortly.</span>
            </div>
          </div>
        </div>
      )}
      <section id="whiteheader" className="p-0">
      <Header />
      </section>
      <section id="registration">
        <div className="registration">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="regnav"></div>
                <div className="registration">
                  <div className="title">
                    <h3>Partner With Us</h3>
                    <p>Write to us at sobha.restoplus@sobha.com or (WhatsApp) <a href="tel:+919880730494">+91 98807 30494</a></p>
                  </div>
                  <div class="trading_parten_form">
                    <div className="form bg-white">
                      <div className="formstn trading_partner">
                        <ul>
                          <li>
                            <input type="text" name="user_name" id="user_name" placeholder='Name*' pattern="[a-zA-Z\s]+" required value={user_name} onChange={(e) => {setusername(e.target.value);}} onKeyUp={handledisable} ref={ref_name} />
                          </li>
                          <li>
                            <input type="tel" name="user_mobile" id="user_mobile" placeholder='Phone No*' value={user_mobile} onChange={(e) => {setusermobile(e.target.value);}}  onKeyUp={handledisable} ref={ref_mobile} />
                          </li>
                          <li>
                            <input type="email" placeholder="Email" name="user_email" id="user_email" value={user_email} onChange={(e) => {setuseremail(e.target.value);}} ref={ref_email} />
                          </li>
                          <li>
                            <input type="text" name="company_name" id="company_name" placeholder='Company Name' value={company_name} onChange={(e) => {setcompanyname(e.target.value);}}  onKeyUp={handledisable} ref={ref_companyname} />
                          </li>
                          <li>
                            <input type="text" name="user_location" id="user_location" placeholder='Location*' value={user_location} onChange={(e) => {setuserlocation(e.target.value);}}  onKeyUp={handledisable} ref={ref_location} />
                          </li>
                          <li>
                            <textarea cols="30" rows="1" id="user_comment" name="user_comment" value={user_comment} onChange={(e) => {setusercomment(e.target.value);}} ref={ref_comment} placeholder="Message"></textarea>
                          </li>
                        </ul>
                        <div className="btns">
                          <div class="button button-2">
                            <input type="submit" value="submit" id="submit_button" onClick={handleSubmit} disabled={isDisabled} className='disabled'/>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TradingForm;
