import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import FirmScale from "./Firm_scale";
import thickness from "../images/thickness.png";
import { Thickness } from "./Thickness";
import { Link } from 'react-router-dom';

var decodeHTML = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}
export const CompareCol = ( {cat, sub_cat, pname, planselect} ) => {

  // const selectedValue = 'genesis';
  // console.log("Deepak " + planselect);
  // alert(cat)
  // const { catName, subcatName, pname } = useParams();
  // const cat = slug;
  // const sub_cat = slug2 ? slug2 : "";
  // const pname = slug3;

  const [pnameVal, setpnameVal] = useState[pname];

  const [pagedata, setPagedata] = useState([]);

  const [enquirefrm, setActive1] = useState("false");
  const [isScrolled, setIsScrolled] = useState(false);

  const [selectedValue, setSelectedValue] = useState('');
  // document.getElementById('selectedValue').value='genesis';
  useEffect(() => {
  setSelectedValue(pname);
    const handleScroll = () => {
      const sectionSize = document.getElementsByClassName('compare_product_img')[0];
      const sectionSpec = document.getElementsByClassName('compare_spec')[0];

      if (sectionSize && sectionSpec) {
        const rectSize = sectionSize.getBoundingClientRect();
        const rectSpec = sectionSpec.getBoundingClientRect();
  
        const isFullyScrolledSize = rectSize.bottom + rectSize.height <= 0;
        const isFullyScrolledSpec = rectSpec.top <= window.innerHeight && rectSpec.bottom >= 0;

        setIsScrolled(isFullyScrolledSize && isFullyScrolledSpec);
      }
    };
    window.addEventListener('scroll', handleScroll);
  
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  const handleToggle1 = () => {
    setActive1(!enquirefrm);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    let mounted = true;

    // Aos.init({ duration: 2000 });

    const loadData = async () => {
      // alert("http://sobha.triverseadvertising.com/api/products.php?c=" + cat + "&sc=" + sub_cat + "&p=" + pname);
      const response = await axios.get(
        window.weburl + `api/products.php?c=${cat}&sc=${sub_cat}&p=${pnameVal}`
      );

      if (mounted) {
        setPagedata(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length == 0) {
    console.log("no data");
    return null;
  }


const [products, setProducts] = useState[""];
  function selectPlanDetails(category) {
    // axios.get(`http://sobha.triverseadvertising.com/api/get_products.php?cid=${category}`)
    //   .then(response => setProducts(response.data))
    //   .catch(error => console.log(error));
    //   document.getElementById('user_warranty').value='';
    alert(category);
  }


  const colorTag = pagedata.products.color_tag.split(",");
  const productImages = pagedata.products.product_images.split("~");

  return (
    <>
      <div className="col">
        <div className="compare_col">
          {planselect ?
            <div className="Compare_product_Name">
              <select value={selectedValue} name="selectedValue" id="selectedValue" onChange={(e) => {pnameVal(e.target.value);}}>
                {planselect.map(selectVal => (
                <option key={selectVal.slug} value={selectVal.slug}>{selectVal.title}</option>
                ))}
              </select>
            </div>
          : null}
          {pnameVal ?
          <>
          <div className="compare_product_img">
            <img src={pagedata.products.product_images.split('~').join('')} alt="" />
          </div>
          <div className="compare_colour">
            {pagedata.products.color_code ? (
              <p class="colorSec">
                {pagedata.products.color_code
                  .split(",")
                  .map((colorCode, colorIndex) => (
                    <span>
                      <circle style={{ background: colorCode }}></circle>
                      <em>{colorTag[colorIndex]}</em>
                    </span>
                  ))}
              </p>
            ) : null}
          </div>
          <div className="Compare_visit">
            <div className="btns">
              <div class="button button-2">
              <a href={"/product/" + cat + "/" + sub_cat + "/" + pname}><input type="button" value="Know more" id="submit_button" /></a>
              </div>
            </div>
          </div>
          <div id="__jdh_" className={isScrolled ? 'campare_sum_visible' : 'campare_sum'}>
             <div className="campare_sum_cnt">
             <img src={pagedata.products.product_images.split('~').join('')} alt="product-image  " />
             </div>
             <div className="campare_sum_cnt">
              <div className="heading">
                <h3>{pagedata.products.title}</h3>
              </div>
              <div className="compare_redirect">
                <a href="">
                <a href={"/product/" + cat + "/" + sub_cat + "/" + pname}><span>View product</span></a>
                </a>
              </div>
             </div>
          </div>
           <div className="compare_size">
            <div className="heading">
              <h3>Size</h3>
            </div>
            <div className="thickness_icon">
              <img src={thickness} alt="" />
            </div>
            {pagedata.products.size_title ? (
              <div className="compare_thickness">
                <strong>{pagedata.products.size_title}</strong>
                <p>
                  {pagedata.products.size.split(",").map((sizeData) => (
                    <span
                      dangerouslySetInnerHTML={createMarkup(sizeData)}
                    ></span>
                  ))}
                </p>
              </div>
            ) : null}
          </div>
          <div className="compare_layering">
            <div className="heading">
              <h3
                dangerouslySetInnerHTML={createMarkup(
                  pagedata.products.layer_title
                )}
              ></h3>
            </div>
            <div className="cateloguestn">
              <div className="catelogueleft">
                <img
                  src={pagedata.products.layer_img}
                  alt={pagedata.products.layer_title}
                />
              </div>
              <div
                className="catelogueright"
                dangerouslySetInnerHTML={createMarkup(
                  pagedata.products.layer_desc
                )}
              ></div>
            </div>
          </div>
          <div className="compare_spec">
            <div className="heading">
              <h3
                dangerouslySetInnerHTML={createMarkup(
                  pagedata.products.confort_title
                )}
              ></h3>
            </div>
            <div className="range">
              <FirmScale toplevel={pagedata.products.confort_flevel} formLevel={pagedata.products.confort_flevel} />
            </div>
            {pagedata.productfeatures ? (
              <div className="feature">
                <div className="heading">
                  <h3
                    dangerouslySetInnerHTML={createMarkup(
                      pagedata.products.features_title
                    )}
                  ></h3>
                </div>
                <div className="featureinfo">
                  {pagedata.productfeatures.map((featureData) => {
                    const featurecontent = featureData.pfeature.split("\n");
                    return (
                      <div className="featurebox">
                        <h4
                          dangerouslySetInnerHTML={createMarkup(
                            featureData.ptitle
                          )}
                        ></h4>
                        <ul>
                          {featurecontent.map((f_content) => (
                            <li
                              dangerouslySetInnerHTML={createMarkup(f_content)}
                            ></li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
          </>
          : null}
        </div>
      </div>
    </>
  );
};
