import React from 'react'
import Quality01 from '../images/eng01.webp'
import Quality02 from '../images/eng02.webp'
import Quality03 from '../images/eng03.webp'
import Quality04 from '../images/eng04.webp'
import Quality05 from '../images/eng05.webp'
import Soundsleep from '../images/sound-sleep.webp'
import Sobhavideo from '../images/techvideo.mp4'
// import Sobhamobile from '../images/mtechvideo.mp4'
export const Quality = () => {
  return (
    <>
      <section id='quality' >
        <div className='quality'>
          <div className='container'>
            <div className='row'>
              <div className='web-container'>
                <div className='col-12 pull-left'>
                  <div className='title'>
                    <span>Quality</span>
                    <h3>Sleep Engineering At Work</h3>
                    <p>YOUR MATTRESS AND SLEEP ACCESSORIES ARE MADE IN A STATE-OF-THE-ART DEDICATED SHOP FLOOR OF 170,000 SQ.FT. AT BANGALORE</p>
                  </div>
                  <div className='qualitystn'>
                    <div className='qualityimgleft'>
                      <img src={Quality01} alt='quality' />
                    </div>
                    <div className='qualityimgright'>
                      <div className='qualitybox'>
                        <img src={Quality02} alt='quality' />
                      </div>
                      <div className='qualitybox'>
                        <img src={Quality03} alt='quality' />
                      </div>
                      <div className='qualitybox'>
                        <img src={Quality04} alt='quality' />
                      </div>
                      <div className='qualitybox'>
                        <img src={Quality05} alt='quality' />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='techvideo' >
        <div className='techvideo'>
          <video autoPlay muted loop playsInline className='desktop-show'>
            <source src={Sobhavideo} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>
          {/* <video autoPlay muted loop playsInline className='mobile-show'>
            <source src={Sobhamobile} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video> */}
          <div className='techcont'>
            <div className='container'>
              <div className='row'>
                <div className='web-container'>
                  <div className='title'>
                    <h3>Tenaciously Tested For You to Get Sound Sleep</h3>
                  </div>
                  <div className='qualitystn tenaciously'>
                    <div className='qualityimgright'>
                      <div className='qualitybox'>
                        <h4>LUXURY FOR<br /> A LIFETIME</h4>
                        <p>Durability test of over 1 lakh bounces on the Cornell testing machine and the Mattress Rollator</p>
                      </div>
                      <div className='qualitybox'>
                        <h4>FINEST FABRIC<br /> AND COTTON</h4>
                        <p>Tested for grammage, abrasion, tensile strength, seam slippage & tear strength</p>
                      </div>
                      <div className='qualitybox'>
                        <h4>SUPERIOR QUALITY<br /> SPRINGS</h4>
                        <p>Tested for composition, strength,durability and indentation hardness</p>
                      </div>
                      <div className='qualitybox'>
                        <h4>FLAWLESS<br /> FOAM</h4>
                        <p>Tested for density, load deflection, support factor, tensile strength, compressive strength,shear fatigue and contamination</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
