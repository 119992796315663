import React from 'react'
import { Sitemap } from './Sitemap';
import { Contactinfo } from './Contactinfo';
export const Footer = () => {
  return (
    <>
    <footer>
        <div className="container">
            <div className="row">
                <div className='web-container'>
              <Contactinfo /> 
               <Sitemap />               
                <div className="copyrights">
                    <p>&copy; 2023 Sobha Restoplus | all rights reserved.</p>
                    <p><a href="https://triverseadvertising.com/" target="_blank" rel="noopener noreferrer">design : triverse</a></p>
                </div>
            </div>
        </div>
        </div>
    </footer>
    </>
  )
}
