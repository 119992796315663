import React from "react";
import Header from "./components/Header";
import { Footer } from "./components/Footer"
import img01 from "./images/Sobha.webp";
import img01_mobile from "./images/Sobha group mobile.webp";
import img02 from "./images/PASSION-AT-WORK-2-1-1.webp";
import { NavLink } from "react-router-dom";
import { Svg } from './components/Svg';
import Slider from "react-slick";
import { Helmet } from "react-helmet";

export const Sobha = () => {
  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 300,
    autoplaySpeed:5000,
    cssEase: "linear",
  };
  return (
    <>
        <Helmet>
  <title>Sobha | Sobha Restoplus</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
      <Header />
      <section id='banner' className='pb-0'>
          <div className='banner'>
            <div className='container'>
                <div className='row'>
                    
            <Slider {...settings}>
                <div>
                  <div className='sliderrow'>
                    <div className='slideimg h-auto '>
                    <img src={img01} alt="Banner" className="show-desktop" />
                    <img src={img01_mobile} alt="Banner" className="show-mobile h-auto" />
                      </div>
                      <div className='slidecont'>
                      <h3>
                      <span></span>
                      </h3>
                       </div>
                    </div>
                </div>
        </Slider>
          </div>
          
          </div>
            </div>
      </section>
      <section id="aboutus">
        <div className="aboutus">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="bg-white col-12">
                  <div className="comfortbox">
                    {/* <div className="logo pb-50">
                      <NavLink to="/">
                        <Svg />
                      </NavLink>
                    </div> */}
                
                    <div className="restrostn">
                      <div className="restroimg">
                        <img src={img02} alt="Sobha" />
                      </div>
                      <div className="sobhacont">
                        <p>
                          SOBHA epitomizes “passion at work” in totality. For us
                          it is not only a catch phrase which sounds just right,
                          rather we all strive to live it daily. It serves as
                          our compass which guides us towards creating world
                          class quality products and workmanship. It guides us
                          to be transparent in all our dealings and adhere to
                          delivery on time, each time.
                        </p>
                        <p>
                          Our track record of being the most reliable and
                          trustworthy builder with a repertoire of award winning
                          properties across India speaks for itself. We changed
                          the skyline in Bangalore, created landmark
                          developments in Kerala and have stepped into Delhi –
                          NCR, Chennai, Coimbatore, Mysore, Pune and many more
                          to follow.
                        </p>

                        <p>
                          SOBHA Ltd. is one of the finest real estate
                          development companies in India – A trusted builder
                          based out of Bangalore, since 1995.
                        </p>
                        <div className="btns justify-content-start">
                          <div class="button button-2">
                            <a
                              href="https://www.sobha.com/philosophy/"
                              target="_blank"
                            >
                              Know more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="bannerstn">
        <div className="bannerstn">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title">
                  <span>Vision & Mission</span>
                </div>
              </div>
              <div className="bgbanner">
                {/* <div className="bgbanner_text">Infosys Mysuru, built by SOBHA</div> */}
                <div className="bgcontbox">
                  <div className="bgbox">
                    <h4>VISION</h4>
                    <p>TRANSFORM THE WAY PEOPLE PERCEIVE ‘QUALITY’</p>
                  </div>
                  <div className="bgbox">
                    <h4>MISSION</h4>
                    <p>NO SHORTCUTS TO QUALITY</p>
                  </div>
                  <div className="bgbox">
                    <h4>PHILOSOPHY</h4>
                    <p>PASSION AT WORK </p>
                  </div>
                </div>
              </div>
              <div className="btns ">
                <div class="button button-2">
                  <a href="https://www.sobha.com/" target="_blank">
                    www.sobha.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
