import React, {useState, useEffect} from 'react'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import img01 from './images/TEST IMAGE.webp'
import Slider from 'react-slick';
import noimg from './images/no_cat_image.webp'
import { Link } from 'react-router-dom';
import axios from 'axios';
import {useParams} from "react-router-dom";
import {Certificate} from "./components/Certificate.js"
import { Clientie } from './components/Clientie';
import { Helmet } from 'react-helmet';

var decodeHTML = function (html) {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

function createMarkup(content) {
  return { __html: decodeHTML(content) };
}

export const Categorypage = () => {
  
  const { slug } = useParams();
  const cat = slug;

  console.log(cat);
  const [pagedata, setPagedata] = useState([]);

  const [enquirefrm, setActive1] = useState("false");

  const handleToggle1 = () => {
      setActive1(!enquirefrm);
  };

  const [data, setData] = useState([]);
  useEffect(() => {

      let mounted = true;

      // Aos.init({ duration: 2000 });

      const loadData = async () => {
          const response = await axios.get(window.weburl + `api/categories.php?c=${cat}`)

          if (mounted) {
              setPagedata(response.data);
          }
      }

      loadData();

      return () => {
          mounted = false;
      }

  }, []);

  console.log(pagedata);
  if (pagedata.length == 0) {
      console.log('no data');
      return null;
  }
  var settings = {
        dots: false,
        navigation: true,
        loop: false,
        fade: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed:5000,
        cssEase: "linear",
      };
      var settings1 = {
        dots: false,
        navigation: true,
        loop: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed:5000,
        cssEase: "linear",
        responsive: [
          {
              breakpoint: 1080,
              settings: {
                  slidesToShow: 3,
              }
          },
          {
              breakpoint: 482,
              settings: {
                  slidesToShow: 2,
              }
          },
          {
              breakpoint: 420,
              settings: {
                  slidesToShow: 1,
              }
          }
      ]
      };
  return (
    <>
     <Helmet>
  <title>{`${pagedata.catname}  | Sobha Restoplus`}</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
     <section id="whiteheader" className="p-0">
      <Header />
      </section>
      <section id='mattresses' className='p-0'>
        <div className='mattresses nobtn dotbgimg'>
            <div className='container'>
            <div className='row'>
                <div className='web-container'>
                    <div className='title'>
                      <span>{pagedata.catname}</span>
                      <h3>The Range of {pagedata.catname}</h3>
                    </div>
                    <div className='comfortslider'>
                    <Slider {...settings1}>
                      {pagedata.subcategories.map(subCats => (
                        <div>
                          <Link to={subCats.layout==1 ? '/products/' + cat + '/' + subCats.slug : '/product/' + cat + '/' + subCats.slug }>
                              <div className='comfortbox'>
                                  <img src={subCats.subcategory_image ? subCats.subcategory_image : noimg} alt='' />
                                    <div className='comfortinfo'>
                                    <h3 dangerouslySetInnerHTML={createMarkup(subCats.sub_category_html)}></h3>
                                    </div>
                                </div>
                              </Link>
                              </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        
      </section>  
      {pagedata.catname == "Hospitality" ?(
        <Clientie clietsData={pagedata.clients}/>
      ): null} 
    <Footer />
    </>
  )
}
