import React from "react";

const FirmScale = ( {formLevel,toplevel} ) => {

  return (
    <div className="firm_scale">
      <div className="firm_pointer">
      <p style={{ left: formLevel + '%',top: toplevel + '%' }} >
      <span data-type="&#9660;"></span>
      </p>
      </div>
      <div className="frim_blocks">
        <ul>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
          <li>6</li>
          <li>7</li>
          <li>8</li>
          <li>9</li>
          <li>10</li>
        </ul>
      </div>
      <div className="firm_text">
      <ul>
      <li>Extra Soft</li>
      <li>Soft</li>
      <li>Medium Firm</li>
      <li>Firm</li>
      <li>Extra Firm</li>
      </ul>
      </div>
    </div>
  );
};

export default FirmScale;
