import React, {useState, useEffect} from 'react'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Slider from 'react-slick';
import { Catelogue } from './components/Catelogue';
import noimg from './images/noimage.jpg'
import axios from 'axios';
import {useParams} from "react-router-dom";
import FirmScale from './components/Firm_scale';
import { CompareUs } from './components/CompareUs';
import { Helmet } from 'react-helmet';

var decodeHTML = function (html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

function createMarkup(content) {
    return { __html: decodeHTML(content) };
}

export const Detailpage = () => {

    const { slug, slug2, slug3 } = useParams();
    const cat = slug;
    const sub_cat = (slug2 ? slug2 : '');
    const pname = slug3;
    const [pagedata, setPagedata] = useState([]);

    const [enquirefrm, setActive1] = useState("false");

    const handleToggle1 = () => {
        setActive1(!enquirefrm);
    };


    const [data, setData] = useState([]);
    useEffect(() => {

        let mounted = true;

        // Aos.init({ duration: 2000 });

        const loadData = async () => {
            // alert("http://sobha.triverseadvertising.com/api/products.php?c=" + cat + "&sc=" + sub_cat + "&p=" + pname);
            const response = await axios.get(window.weburl + `api/products.php?c=${cat}&sc=${sub_cat}&p=${pname}`)

            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        }

    }, []);

    console.log(pagedata);
    if (pagedata.length == 0) {
        console.log('no data');
        return null;
    }
    const colorTag = pagedata.products.color_tag.split(",");
    const productImages = pagedata.products.product_images.split("~");

    

    var settings = {
        dots: false,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed:5000,
        cssEase: "linear",
      };

    var settings1 = {
        dots: false,
        navigation: true,
        loop: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        autoplaySpeed:5000,
        cssEase: "linear",
        responsive: [
          {
              breakpoint: 630,
              settings: {
                  slidesToShow: 2,
              }
          },
          {
              breakpoint: 450,
              settings: {
                  slidesToShow: 1,
              }
          }
      ]
      };
  return (
    <>
<Helmet>
  <title>{`${pagedata.products.metatitle} | Sobha Restoplus`}</title>
  <meta name="description" content="" />
  <link rel="canonical" href={window.location.href} />
</Helmet>
    <section id='whiteheader' className='p-0'>
    <Header />
    </section>
    
    <section id='detalis' className='p-0'>
        <div className='details'>
                <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='detailsstn'>
                                <>
                                {(pagedata.products.warranty_period!=0)? 
                                 <div className="warrenty_period">
                                  <ul>
                                  <li><i >&#9733;</i>{pagedata.products.warranty_period}<i>&#9733;</i></li>
                                  <li>YEAR</li>
                                  <li>WARRANTY</li>
                                  <li><div className="underline_wht">
                                    <span></span> <span></span></div></li>
                                  </ul>
                                 </div>
                                     :null}</>
                                    <div className='detailimg'>
                                        {productImages.map(proImages => (
                                        <img src={proImages} alt=''/>
                                        ))}
                                    </div>
                                    <div className='detailinfo'>
                                                <h4>{pagedata.subcategory}</h4>
                                                <h2 dangerouslySetInnerHTML={createMarkup(pagedata.products.title)}></h2>
                                                <p dangerouslySetInnerHTML={createMarkup(pagedata.products.p_desc)}></p>
                                       <ul>
                                                {pagedata.products.size_title ?
                                                    <li><strong>{pagedata.products.size_title}</strong>
                                                        <p>
                                                        {pagedata.products.size.split(",").map(sizeData => (
                                                            <span dangerouslySetInnerHTML={createMarkup(sizeData)}></span>
                                                            ))}
                                                        </p>
                                                    </li>
                                                : null}
                                                {pagedata.products.color_title ?
                                        <li>
                                            <strong>{pagedata.products.color_title}</strong>
                                            {pagedata.products.color_code ?
                                            <p class='colorSec'>
                                                {pagedata.products.color_code.split(",").map((colorCode, colorIndex) => (
                                                    
                                                    <span><circle style={{ background: colorCode }}></circle>
                                                    <em>{colorTag[colorIndex]}</em>
                                                    </span>
                                                ))}
                                            </p>
                                            : null}
                                        </li>
                                        : null}
                                       </ul>
                                    </div>
                                </div>
                                </div>
                        </div>
                </div>
        </div>
    </section>
    <section id='precise'>
        <div className='precise'>
                <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='heading'>
                                        <h3 dangerouslySetInnerHTML={createMarkup(pagedata.products.layer_title)}></h3>
                                </div>
                            <div className='cateloguestn'>
                                <div className='catelogueleft'>
                                    <img src={pagedata.products.layer_img} alt={pagedata.products.layer_title} />
                                </div>
                                <div className='catelogueright' dangerouslySetInnerHTML={createMarkup(pagedata.products.layer_desc)}></div>
                            </div>
                            </div>
                        </div>
                </div>
        </div>
    </section>
    <section id='firmnesslevel'>
        <div className='firmnesslevel'>
                <div className='container'>
                        <div className='row'>
                                <div className='web-container'>
                                    <div className='heading'>
                                        <h3 dangerouslySetInnerHTML={createMarkup(pagedata.products.confort_title)}></h3>
                                    </div>
                                    <div className='range'>
                                        <FirmScale formLevel={pagedata.products.confort_flevel}/>    
                                    </div>
                                    {pagedata.productfeatures ?
                                    <div className='feature'>
                                        <div className='heading'>
                                                <h3 dangerouslySetInnerHTML={createMarkup(pagedata.products.features_title)}></h3>
                                        </div>
                                        <div className='featureinfo'>
                                            {pagedata.productfeatures.map(featureData => {
                                                const featurecontent = featureData.pfeature.split("\n");
                                            return <div className='featurebox'>
                                                <h4 dangerouslySetInnerHTML={createMarkup(featureData.ptitle)}></h4>
                                                <ul>
                                                    {featurecontent.map(f_content => (
                                                    <li dangerouslySetInnerHTML={createMarkup(f_content)}></li>
                                                    ))}
                                                </ul>
                                            </div>
                                            })}
                                        </div>
                                    </div>
                                    : null}
                                </div>
                        </div>
                </div>
        </div>
    </section>
    {/* <CompareUs catId = {pagedata.catgegory_id} prod2 = {pagedata.rproducts[0].slug} prod3 = {pagedata.rproducts[1].slug}/> */}
    {pagedata.rproducts ?
    <section id='mattresses' className='no-img'>
        <div className='mattresses nobtn'>
            <div className='container'>
            <div className='row'>
                <div className='web-container'>
                    <div className='title'>
                      <h3>Related Products</h3>
                    </div>
                    <div className='comfortslider'>
                    <Slider {...settings1}>
                        {pagedata.rproducts.map(relatedpro => (
                        <div>
                            <a href={'/product/' + cat + '/' + sub_cat + '/' + relatedpro.slug}>
                              <div className='comfortbox'>
                                <div className='productimg'>
                              <img src={relatedpro.image ? relatedpro.image : noimg} alt='' />
                              </div>
                              <div className='productcont'>
                              <p  dangerouslySetInnerHTML={createMarkup(relatedpro.title)}></p>
                              </div>
                                </div>
                                </a>
                              </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </section>
      : null}
        <Catelogue cat={cat}/>
    <Footer />
    </>
  )
}
